import { styled } from "@mui/material/styles";
import { Box, Typography as MuiTypography } from "@mui/material";

export const Label = styled(MuiTypography)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
    color: theme.palette.grey["600"],
    marginLeft: theme.spacing(1.4),
    paddingTop: theme.spacing(1),
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: "10px",
  },
}));

export const ContainerItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "10px",
  marginTop: "10px",
  minHeight: "52px",
  maxHeight: "52px",
  flex: 1,
  fieldset: {
    border: "unset",
  },
  "&:first-of-type": {
    marginTop: 0,
  },
  ".MuiTypography-caption": {
    display: "none",
  },
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  input: {
    fontSize: "14px",
    fontWeight: 400,
  },

  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    borderRadius: 0,
    borderRight: "1px solid #8b8fa3",
    minHeight: "68px",
    maxHeight: "68px",
    ".MuiTypography-caption": {
      display: "flex",
    },
    ".MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    ".MuiAutocomplete-root": {
      display: "flex",
      flex: 1,
    },
    ".MuiAutocomplete-root .MuiOutlinedInput-root": {
      padding: 0,
    },
    ".MuiFormControl-root": {
      flex: 1,
      justifyContent: "center",
    },
    input: {
      padding: 0,
    },
  },
}));

export const LocationContainer = styled(ContainerItem)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flex: 2.5,
    paddingLeft: "10px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
}));

export const DateContainer = styled(ContainerItem)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flex: 2,
  },
}));

export const TimeContainer = styled(ContainerItem)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flex: 1.5,
  },
}));

export const GolfersContainer = styled(ContainerItem)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    borderRight: "unset",
    flex: 0,
    paddingRight: "20px",
  },
}));

export const SearchbarContainer = styled(ContainerItem)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: "100px",
    borderRight: "unset",
    padding: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
}));

export const IconLabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "14px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const CounterContainer = styled(Box)(({ theme }) => ({
  paddingRight: "10px",
  [theme.breakpoints.up("md")]: {},
}));
