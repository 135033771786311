import { useState } from "react";

import { Box, Typography, Slider } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";

type TimeSliderProps = {
  step?: number;
  min?: number;
  max?: number;
  time?: { alignment: string; value: number[] };
  onChange: (value: { alignment: string; value: number[] }) => void;
  [x: string]: unknown;
};

export const TIME_SLIDER_LATEST_TIME_SELECTABLE = 18;

export const TimeSlider = ({
  step = 1,
  min = 6,
  max = TIME_SLIDER_LATEST_TIME_SELECTABLE,
  time,
  onChange,
}: TimeSliderProps) => {
  const [value, setValue] = useState(time?.value);

  const timeMarks = [
    {
      value: 6,
      label: "6am",
    },
    {
      value: 18,
      label: "6pm+",
    },
  ];
  const handleChange = (
    event: React.SyntheticEvent | Event,
    newV: number | number[],
  ) => {
    const newValue = newV as number[];
    let alignment = "";
    if (newValue[0] === 6 && newValue[1] === 11) {
      alignment = "Morning";
    } else if (newValue[0] === 11 && newValue[1] === 15) {
      alignment = "Afternoon";
    } else {
      if (
        newValue[0] === 15 &&
        newValue[1] === TIME_SLIDER_LATEST_TIME_SELECTABLE
      ) {
        alignment = "Evening";
      } else if (
        newValue[0] === 6 &&
        newValue[1] === TIME_SLIDER_LATEST_TIME_SELECTABLE
      ) {
        alignment = "Any time";
      } else {
        alignment = "Custom";
      }
    }
    setValue(newValue);
    onChange({ alignment, value: newValue });
  };

  const handleAlignment = (
    _: React.SyntheticEvent | Event,
    newAlignment: string,
  ) => {
    let newValue;
    switch (newAlignment) {
      case "Morning":
        newValue = [6, 11];
        break;
      case "Afternoon":
        newValue = [11, 15];
        break;
      case "Evening":
        newValue = [15, TIME_SLIDER_LATEST_TIME_SELECTABLE];
        break;
      default:
        newAlignment = "Any time";
        newValue = [6, TIME_SLIDER_LATEST_TIME_SELECTABLE];
        break;
    }
    setValue(newValue);
    onChange({ alignment: newAlignment, value: newValue });
  };

  const handleTime = (v: number) => {
    if (v < 12) {
      return `${v}am`;
    } else if (v >= 18) {
      return "6pm+";
    } else if (v > 12) {
      return `${v - 12}pm`;
    } else {
      return `${v}pm`;
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <ToggleButtonGroup
        sx={{ marginTop: 0 }}
        value={time?.alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="time buttons"
      >
        <ToggleButton value="Morning" aria-label="morning">
          <Typography>Morning</Typography>
        </ToggleButton>
        <ToggleButton value="Afternoon" aria-label="afternoon">
          <Typography>Afternoon</Typography>
        </ToggleButton>
        <ToggleButton value="Evening" aria-label="evening">
          <Typography>Evening</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <Slider
        {...{ step, min, max }}
        marks={timeMarks}
        onChangeCommitted={handleChange}
        onChange={(_, val) => setValue((val as number[])?.slice())}
        value={value?.slice()}
        getAriaValueText={(v) => v.toString()}
        valueLabelFormat={(v) => handleTime(v)}
        valueLabelDisplay="on"
        aria-labelledby={"time-range-slider"}
      />
    </Box>
  );
};
