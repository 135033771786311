import { ButtonProps, Button as MaterialButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const BaseButton = styled(MaterialButton)(({ theme }) => ({
  color: theme.palette.text.button,
  maxWidth: "330px",
  fontWeight: "bold",
  lineHeight: "17px",
  fontSize: 14,
}));

const FullButton = styled(BaseButton)({
  padding: 16,
  margin: "24px 0",
});

const _TextButton = styled(MaterialButton)({
  width: "auto",
  fontWeight: "bold",
  paddingTop: 0,
  paddingBottom: 0,
});

export const Button = ({ children, className, ...props }: ButtonProps) => {
  return (
    <FullButton className={className} {...props}>
      {children}
    </FullButton>
  );
};

export const TextButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <_TextButton {...props} className={className} variant="text">
      {children}
    </_TextButton>
  );
};
