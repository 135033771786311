import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { ClockIconFilled } from "@common";
import { Bolt } from "@mui/icons-material";
import aiLanding from "@assets/images/ai-landing.png";
import { appConfig } from "@services/AppConfig";
const { isAssistantEnabled } = appConfig;

const AiImageWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundSize: "contain",
  backgroundImage: `url(${aiLanding})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});

function AiSection() {
  return isAssistantEnabled ? (
    <SectionContainer>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ height: { xs: 450, md: "auto" }, mb: { xs: 3, md: 0 } }}
          >
            <AiImageWrapper />
          </Grid>

          <Grid xs={0} md={1} item>
            <Divider
              orientation="vertical"
              sx={{ borderColor: "#DCF7E1", mr: "40px" }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="p1"
                  gutterBottom
                  sx={{
                    textTransform: "uppercase",
                  }}
                  color="green.secondary"
                >
                  New Feature
                </Typography>
                <Typography variant="h2" gutterBottom sx={{ marginTop: 2 }}>
                  AI-Powered Chat for Seamless Golf Booking
                </Typography>
                <Box display={"flex"} flexDirection={"row"}>
                  <Box color={"green.primary"}>
                    <Bolt />
                  </Box>
                  <Box ml={2}>
                    <Typography variant={"p1"}>
                      Instant Assistance:{" "}
                      <Typography variant={"p2"} color={"grey.600"}>
                        Engage in real-time conversations with our AI chat
                        feature for quick answers to your queries, ensuring a
                        smooth and effortless user experience.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"row"}>
                  <Box color={"green.primary"}>
                    <ClockIconFilled />
                  </Box>
                  <Box ml={2}>
                    <Typography variant={"p1"}>
                      24/7 Availability:{" "}
                      <Typography variant={"p2"} color={"grey.600"}>
                        Enjoy the convenience of round-the-clock support. Our AI
                        chat is always ready to assist, whether you're planning
                        a midnight golf adventure or an early morning tee off.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  ) : null;
}

export default AiSection;
