import Grid from "../common/layout/Grid";
import { UserMessage } from "./UserMessage";
import { AssistantMessage } from "./AssistantMessage";

export function ChatMessage({
  message,
  role,
  userInitials,
}: {
  message: string;
  role: "user" | "assistant";
  userInitials?: string;
}) {
  return (
    <Grid container direction="row" p={2}>
      <Grid>
        {role === "user" ? (
          <UserMessage message={message} userInitials={userInitials} />
        ) : (
          <AssistantMessage message={message} />
        )}
      </Grid>
    </Grid>
  );
}
