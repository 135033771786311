import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import { RangeSlider } from "@common";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  FormGroup,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  DISTANCE_MAX,
  DISTANCE_MIN,
  PRICE_MAX,
  PRICE_MIN,
  useFilter,
} from "@services/Search";

const Container = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    width: "max-content",
    top: theme.spacing(5.5),
  },
}));

const Underline = styled(Typography)({
  fontWeight: 700,
  fontSize: "14px",
  textDecoration: "underline",
});

export const HoleSelect = ({
  holes,
  setEighteenHoles,
  setNineHoles,
  isOpen,
  setOpen,
}: {
  holes: { eighteenHoles: boolean; nineHoles: boolean };
  setEighteenHoles: (val: boolean) => void;
  setNineHoles: (val: boolean) => void;
  isOpen: boolean;
  setOpen: () => void;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const canBeOpen = isOpen && Boolean(buttonRef.current);
  const id = canBeOpen ? "transition-popper-holes" : undefined;
  const [filter, setFilter] = useFilter();
  const getHoles = () => {
    if (filter?.eighteenHoles && filter?.nineHoles) {
      return "18,9";
    } else if (filter?.eighteenHoles) {
      return "18";
    } else if (filter?.nineHoles) {
      return "9";
    } else {
      return "";
    }
  };
  return (
    <>
      <Button ref={buttonRef} fullWidth variant="outlined" onClick={setOpen}>
        Holes: {getHoles()}
      </Button>
      <Popper
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        transition
        sx={{ width: "100vw" }}
      >
        {({ TransitionProps }) => (
          <Box sx={{ zIndex: 1200, width: "70%", marginLeft: 2, marginTop: 1 }}>
            <Fade {...TransitionProps} timeout={350}>
              <Container elevation={6}>
                <Box>
                  <FormGroup sx={{ p: 2 }}>
                    <FormControlLabel
                      label="9 holes"
                      control={
                        <Checkbox
                          name="nineHoles"
                          checked={holes?.nineHoles}
                          onChange={(e) => setNineHoles(e.target.checked)}
                        />
                      }
                    />
                    <FormControlLabel
                      label="18 holes"
                      control={
                        <Checkbox
                          name="eighteenHoles"
                          checked={holes?.eighteenHoles}
                          onChange={(e) => setEighteenHoles(e.target.checked)}
                        />
                      }
                    />
                  </FormGroup>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" py={1} pr={2}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={setOpen}
                      sx={{ fontWeight: 700, fontSize: "1rem" }}
                    >
                      <Underline>Cancel</Underline>
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setFilter({
                          ...filter,
                          eighteenHoles: holes.eighteenHoles,
                          nineHoles: holes.nineHoles,
                        });
                        setOpen();
                      }}
                    >
                      <Underline>Apply</Underline>
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Fade>
          </Box>
        )}
      </Popper>
    </>
  );
};

export const PriceSelect = ({
  price,
  setPrice,

  isOpen,
  setOpen,
}: {
  price: number[];
  setPrice: (price: number[]) => void;

  isOpen: boolean;
  setOpen: () => void;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const canBeOpen = isOpen && Boolean(buttonRef.current);
  const id = canBeOpen ? "transition-popper-price" : undefined;
  const [filter, setFilter] = useFilter();
  const getPrice = () => {
    if (price[0] === PRICE_MIN && price[1] === PRICE_MAX) {
      return "All";
    } else {
      return `$${price[0]} - $${price[1]}`;
    }
  };

  return (
    <>
      <Button ref={buttonRef} fullWidth variant="outlined" onClick={setOpen}>
        Price: {getPrice()}
      </Button>
      <Popper
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        transition
        sx={{ width: "100vw" }}
      >
        {({ TransitionProps }) => (
          <Box
            sx={{ zIndex: 1200, width: "90%", margin: "0 auto", marginTop: 1 }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Container elevation={6}>
                <Box p={4} pt={6} display={"flex"}>
                  <RangeSlider
                    max={100}
                    min={0}
                    name="price"
                    handleChangeCommitted={(newValue) =>
                      setPrice(newValue as number[])
                    }
                    units="$"
                    defaultValue={price}
                  />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="flex-end" py={1} pr={2}>
                  <Button variant="text" color="primary" onClick={setOpen}>
                    <Underline>Cancel</Underline>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setFilter({ ...filter, price });
                      setOpen();
                    }}
                  >
                    <Underline>Apply</Underline>
                  </Button>
                </Box>
              </Container>
            </Fade>
          </Box>
        )}
      </Popper>
    </>
  );
};

export const DistanceSelect = ({
  distance,
  setDistance,

  isOpen,
  setOpen,
}: {
  distance: number[];
  setDistance: (distance: number[]) => void;

  isOpen: boolean;
  setOpen: () => void;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const canBeOpen = isOpen && Boolean(buttonRef.current);
  const id = canBeOpen ? "transition-popper-distance" : undefined;
  const [filter, setFilter] = useFilter();
  const getDistance = () => {
    if (distance[0] === DISTANCE_MIN && distance[1] === DISTANCE_MAX) {
      return "All";
    } else {
      return `${distance[0]} - ${distance[1]}`;
    }
  };
  return (
    <>
      <Button ref={buttonRef} fullWidth variant="outlined" onClick={setOpen}>
        Distance: {getDistance()}
      </Button>
      <Popper
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        transition
        sx={{ width: "100vw" }}
      >
        {({ TransitionProps }) => (
          <Box
            sx={{ zIndex: 1200, width: "90%", margin: "0 auto", marginTop: 1 }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Container elevation={6}>
                <Box p={4} pt={6} display={"flex"}>
                  <RangeSlider
                    max={50}
                    min={0}
                    name="distance"
                    handleChangeCommitted={(newValue) =>
                      setDistance(newValue as number[])
                    }
                    units="miles"
                    defaultValue={distance}
                  />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="flex-end" py={1} pr={2}>
                  <Button variant="text" color="primary" onClick={setOpen}>
                    <Underline>Cancel</Underline>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setFilter({ ...filter, distance });
                      setOpen();
                    }}
                  >
                    <Underline>Apply</Underline>
                  </Button>
                </Box>
              </Container>
            </Fade>
          </Box>
        )}
      </Popper>
    </>
  );
};
