import { useState } from "react";
import { Box, IconButton, Popover, Typography, useTheme } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { Share } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { logClickEvent } from "@services/Analytics";

const Underline = styled(Typography)({
  fontWeight: 700,
  fontSize: "14px",
  textDecoration: "underline",
});
export const ShareButton = () => {
  const [shareMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const shareUrl = document.location.href;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <TwitterShareButton
            onClick={() =>
              logClickEvent({
                label: "twitter",
                category: "share",
              })
            }
            url={shareUrl}
            title="TeeTimePortal-Golf Course Times"
          >
            <Box mr={0.5}>
              <TwitterIcon size={32} round={true} />
            </Box>
          </TwitterShareButton>
          <FacebookShareButton
            url={shareUrl}
            onClick={() =>
              logClickEvent({
                label: "facebook",
                category: "share",
              })
            }
            title="TeeTimePortal-Golf Course Times"
          >
            <Box mr={0.5}>
              <FacebookIcon size={32} round={true} />
            </Box>
          </FacebookShareButton>
          <EmailShareButton
            url={shareUrl}
            subject="TeeTimePortal-Golf Course Times"
            onClick={() =>
              logClickEvent({
                label: "email",
                category: "share",
              })
            }
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </Box>
      </Popover>
      <IconButton
        onClick={(event) => {
          logClickEvent({
            label: "share",
            category: "share",
          });
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.text.primary,
        }}
      >
        <Share sx={{ color: "green.primary" }} />
        <Underline>{shareMessage ? shareMessage : "Share"}</Underline>
      </IconButton>
    </>
  );
};
