import { Suspense } from "react";
import AppRouter from "./routes/AppRouter";
import { FullScreenCircularProgress, LoadingScreen } from "@common";
import { TeeTimeAssistantDrawer } from "@/components/assistant/TeeTimeAssistantDrawer";
import { appConfig } from "@services/AppConfig";
import { useGoogleJsApiLoader } from "@services/Google";
import { useSyncFilterWithQueryParams } from "@services/Search";
import { handleAuthChange } from "@services/Authentication";
import { useFirebaseInitialization } from "@services/Firebase";
import { initializeApi } from "@api";

const { isAssistantEnabled } = appConfig;

initializeApi();

export default function App() {
  const { isFirebaseInitialized } = useFirebaseInitialization({
    onAuthChange: handleAuthChange,
  });
  const isGoogleMapsLoaded = useGoogleJsApiLoader();

  useSyncFilterWithQueryParams();

  if (!isFirebaseInitialized || !isGoogleMapsLoaded)
    return <FullScreenCircularProgress />;

  return (
    <Suspense fallback={<LoadingScreen isLoading />}>
      <AppRouter />
      {isAssistantEnabled && <TeeTimeAssistantDrawer />}
    </Suspense>
  );
}
