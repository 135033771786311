import { Box, CircularProgress, Typography } from "@mui/material";
import { ImageWrapper } from "@pages/LandingPage/SearchSection";

export const LoadingScreen = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
}) => {
  return isLoading ? (
    <ImageWrapper sx={{ flex: 1 }}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            p: 3,
          }}
        >
          <CircularProgress sx={{ color: "green.primary" }} size={70} />
          <Typography variant={"h2"} color={"white"} mt={2}>
            Searching courses that match your criteria...
          </Typography>
        </Box>
      </Box>
    </ImageWrapper>
  ) : (
    <>{children}</>
  );
};
