import { FirebaseOptions } from "@firebase/app";

type AppConfig = {
  googleMapsApiKey: string;
  baseUrl: string;
  isAssistantEnabled?: boolean;
  supportEmail?: string;
  sentryDsn?: string;
  stage: string;
  googleTenantId: string;
  firebaseOptions: FirebaseOptions;
  yearlyMembershipPrice: number;
  freeUserDaysSearchLimit: number;
  trialUserDaysSearchLimit: number;
  premiumUserDaysSearchLimit: number;
};

export const appConfig: AppConfig = {
  freeUserDaysSearchLimit: 3,
  trialUserDaysSearchLimit: 10,
  premiumUserDaysSearchLimit: 10,
  stage: import.meta.env.VITE_APP_STAGE || "local",
  sentryDsn: "https://4f61192daa01405c9c771dcec98b2e25@sentry.sparklabs.us/56",
  supportEmail: "teetimeportal@golfconnect.com",
  isAssistantEnabled: false,
  googleMapsApiKey: "AIzaSyCVOOYGo4wrSKeP27C19lHvvaNrnkOEZmU",
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  googleTenantId: import.meta.env.VITE_APP_GOOGLE_TENANT_ID,
  // TODO: Move this to environment variables
  firebaseOptions: {
    apiKey: "AIzaSyBB0JQqsHfZcFr01eEb-RkF_5Hhcr-Qwf8",
    authDomain: "teetimeportal.firebaseapp.com",
    projectId: "teetimeportal",
    storageBucket: "teetimeportal.appspot.com",
    messagingSenderId: "140893670688",
    appId: "1:140893670688:web:4b0e5bfd1033886836db45",
    measurementId: "G-4ZPJSRZ6YM",
  },
  // TODO: Eventually move this to environment variable/SSM parameter?
  yearlyMembershipPrice: 39.99,
};
