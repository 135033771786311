import { BookingTeeTimeCard, MiniTeeTimeCard, TextButton } from "@common";
import { Box, Button, Typography } from "@mui/material";
import { isMobile } from "@utils";
import { format, parseISO } from "date-fns";
import { useEffect } from "react";
import { useAppNavigate } from "@services/Navigation";
import { Mail, OpenInNew, SendToMobile } from "@mui/icons-material";

import { logBeginCheckoutEvent, logClickEvent } from "@services/Analytics";
import {
  GetCourse200,
  GetCourse200AllOfTeetimesItem,
} from "@thesparklaboratory/teetimeportal-react-query-client/dist/api/teetimeApi.schemas";

export const TeeTimeFlow = ({
  course,
  selectedTeetimeId,
  handleBook,
  teetime,
}: {
  selectedTeetimeId: string | null;
  handleBook: (teeTime: GetCourse200AllOfTeetimesItem | null) => void;
  teetime: GetCourse200AllOfTeetimesItem;
  course: GetCourse200;
}) => {
  const mobile = isMobile();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (!teetime) {
      navigate.toCoursePage(course?.courseId);
    }
  }, [teetime, course]);

  const shareEmail = () => {
    const body = `Course: ${course?.name}
Tee Time: ${format(
      parseISO(teetime?.date),
      "ddd, MMM Do @ h:mm a",
    )} for ${teetime?.holeCount} holes
Price: $${teetime?.priceMin}
URL: ${window.location.href}`;

    window.location.href = `mailto:?&subject=${encodeURIComponent(
      "Check out this Tee Time from TeeTimePortal",
    )}&body=${encodeURIComponent(body)}`;
  };

  const mobileShare = async () => {
    try {
      const shareData = {
        title: "TeeTimePortal Golf",
        text: "Check out this tee time",
        url: window.location.href,
      };
      await navigator.share(shareData);
    } catch (e) {
      //handle error
    }
  };

  const bookUrl = (data: GetCourse200AllOfTeetimesItem) => {
    window.open(data?.bookingUrl, "_blank");
    logBeginCheckoutEvent({
      value: data?.priceMin,
      items: [
        {
          item_id: data?.teetimeId,
          item_name: `${course?.name} ${data?.date} ${data?.holeCount} holes`,
          price: data?.priceMin,
          item_brand: course?.facility?.name,
          item_category: "Tee Time",
          item_variant: `${data?.date} ${data?.holeCount} holes`,
          quantity: 1,
        },
      ],
    });
  };
  return selectedTeetimeId === teetime?.teetimeId ? (
    <Box width="100%">
      <TextButton
        sx={{ margin: "16px 0" }}
        onClick={() => {
          logClickEvent({
            label: "back-to-tee-times",
            category: "navigation",
          });
          handleBook(null);
        }}
      >
        Back to all Tee Times
      </TextButton>
      <BookingTeeTimeCard
        time={teetime?.date}
        price={teetime?.priceMin}
        holeCount={teetime?.holeCount}
        playerMax={teetime?.playerMax}
        playerMin={teetime?.playerMin}
      />
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        {teetime?.bookingUrl && (
          <Button
            onClick={() => bookUrl(teetime)}
            variant="secondary"
            fullWidth
          >
            <OpenInNew />
            <Typography sx={{ ml: 1 }} variant={"button"}>
              Book Tee Time
            </Typography>
          </Button>
        )}
        {mobile && (
          <Button
            onClick={mobileShare}
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
          >
            <SendToMobile />
            <Typography sx={{ ml: 1 }} variant={"button"}>
              Share Tee Time via text/SMS
            </Typography>
          </Button>
        )}
        <Button
          onClick={shareEmail}
          variant="outlined"
          fullWidth
          sx={{ mt: 1 }}
        >
          <Mail />
          <Typography sx={{ ml: 1 }} variant={"button"}>
            Share Tee Time via email
          </Typography>
        </Button>
      </Box>
    </Box>
  ) : selectedTeetimeId === null ? (
    <MiniTeeTimeCard onClick={() => handleBook(teetime)} {...teetime} />
  ) : null;
};
