import { Slider, SliderProps } from "@mui/material";
import { useState } from "react";

type RangeSliderProps = {
  defaultValue: number[];
  handleChangeCommitted: (val: number | number[]) => void;
  units?: string;
} & SliderProps;

export const RangeSlider = ({
  defaultValue,
  handleChangeCommitted,
  ...props
}: RangeSliderProps) => {
  const [value, setValue] = useState(defaultValue?.slice());

  const valueLabelFormat = (v: number) => {
    const vb = isNaN(v) ? 0 : v;
    switch (props?.units) {
      case "$":
        switch (vb) {
          case 100:
            return `$${vb}+`;
          default:
            return `$${vb}`;
        }
      case "miles":
        return `${vb} mi`;
      default:
        return vb;
    }
  };

  return (
    <Slider
      {...props}
      onChange={(_, val) => setValue(val as number[])}
      onChangeCommitted={(_, value) => handleChangeCommitted(value)}
      value={value?.slice()}
      getAriaValueText={(v) => v.toString()}
      valueLabelFormat={valueLabelFormat}
      aria-labelledby={`${props?.name}-range-slider`}
      valueLabelDisplay="on"
    />
  );
};
