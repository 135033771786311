import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Subscription } from "@/types/subscription";
import { styled } from "@mui/material/styles";
import premiumBgImg from "@assets/images/premium-bg.png";
import { CurrentPlan } from "@pages/AccountPage/CurrentPlan";
import {
  getGetUserQueryKey,
  useDeleteStripeSubscription,
  useGetStripeCreateCheckoutSession,
  useGetStripeSubscriptionProducts,
  useUpdateToPaidStripeSubscription,
  useManageStripeSubscriptionBilling,
} from "@thesparklaboratory/teetimeportal-react-query-client";
import { logger } from "@services/Logger";
import { closeSnackbar, useSnackbar } from "notistack";
import { LoadingWrapper } from "@common";
import { queryClient } from "@services/QueryClient";
import { useEffect, useMemo, useRef, useState } from "react";
import { differenceInCalendarDays, format, isBefore } from "date-fns";
import { useOnSubscriptionUpdated, useUser } from "@services/User";
import { logPremiumTrialCheckoutEvent } from "@services/Analytics";
import { appConfig } from "@services/AppConfig";
import { formatCurrency } from "@/utils/formatCurrency";

export const BackgroundImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${premiumBgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.65)", // Adjust the opacity as needed
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
});

export const SubscriptionTab = ({
  subscription,
}: {
  subscription: Subscription | null;
}) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const { data: userData, isPending: isUserPending } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionUpdating, setIsSubscriptionUpdating] = useState(false);

  useOnSubscriptionUpdated({
    enabled: isSubscriptionUpdating,
    currentUpdatedAt: userData?.data?.subscription?.updatedAt,
    onSubscriptionUpdated: () => {
      setIsSubscriptionUpdating(false);
      timeout && clearTimeout(timeout.current);
    },
  });

  //Need a way to handle the polling going too long
  useEffect(() => {
    if (isSubscriptionUpdating) {
      timeout.current = setTimeout(() => {
        setIsSubscriptionUpdating(false);
        enqueueSnackbar("Stripe is still processing your request...", {
          variant: "warning",
          autoHideDuration: null,
          action: (key) => (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  setIsSubscriptionUpdating(true);
                  closeSnackbar(key);
                }}
              >
                Keep Waiting
              </Button>
            </>
          ),
        });
      }, 10000);

      return () => {
        setIsSubscriptionUpdating(false);
        clearTimeout(timeout.current);
      };
    }
  }, [isSubscriptionUpdating]);
  const {
    mutate: createCheckoutSession,
    isPending: isCreateSubscriptionPending,
  } = useGetStripeCreateCheckoutSession();

  const {
    mutate: manageSubscriptionBilling,
    isPending: isManageBillingPending,
  } = useManageStripeSubscriptionBilling();

  const {
    mutate: updateToPaidSubscription,
    isPending: isUpdateSubscriptionPending,
  } = useUpdateToPaidStripeSubscription();

  const isTrialExpired = useMemo(() => {
    if (!subscription?.trialEndsAt) return false;
    return isBefore(new Date(subscription?.trialEndsAt), new Date());
  }, [subscription?.trialEndsAt]);

  const paymentFailedDate = useMemo(() => {
    if (!subscription?.paymentFailedAt) return null;
    return format(new Date(subscription.paymentFailedAt), "MMMM d, yyyy");
  }, [subscription?.paymentFailedAt]);

  const cancelDate = useMemo(() => {
    if (!subscription?.cancelsAt) return null;
    return format(new Date(subscription.cancelsAt), "MMMM d, yyyy");
  }, [subscription?.cancelsAt]);

  const renewDate = useMemo(() => {
    if (!subscription?.renewsAt) return null;
    return format(new Date(subscription.renewsAt), "MMMM d, yyyy");
  }, [subscription?.renewsAt]);

  const timeLeft = useMemo(() => {
    if (!subscription?.trialEndsAt) {
      return null;
    }

    const daysUntil = differenceInCalendarDays(
      new Date(subscription?.trialEndsAt),
      new Date(),
    );
    const dateEnding = format(
      new Date(subscription?.trialEndsAt),
      "MMMM d, yyyy",
    );
    return { days: daysUntil, date: dateEnding };
  }, [subscription?.trialEndsAt]);

  const { data: productsResponse } = useGetStripeSubscriptionProducts();
  const { mutate: deleteSubscription, isPending: isDeletingSubscription } =
    useDeleteStripeSubscription();

  const handleCreateCheckoutSession = async ({
    isTrial,
  }: {
    isTrial: boolean;
  }) => {
    const email = userData?.data?.email;
    const productId = productsResponse?.data[0]?.id;
    if (!email || !productId) {
      logger.error({
        message: "Error creating checkout session",
        error: "Email or product id is missing",
        data: { email, productId },
      });
      return enqueueSnackbar("Error upgrading subscription, please try again", {
        variant: "error",
      });
    }

    createCheckoutSession(
      {
        data: {
          email,
          productId,
          isTrial,
          successUrl: isTrial
            ? "/success?tracked=false"
            : "/success?sub=premium&tracked=false",
          cancelUrl: "/cancel",
        },
      },
      {
        onSuccess: (data: { data: string }) => {
          logPremiumTrialCheckoutEvent({ productId });
          const checkoutUrl = data?.data;
          window.location.href = checkoutUrl; // Redirect to the provided URL
        },
        onError: (error: unknown) => {
          enqueueSnackbar("Subscription was not created, please try again", {
            variant: "error",
          });
          logger.error({ message: "Error creating subscription", error });
        },
      },
    );
  };

  const handleDelete = () => {
    setIsSubscriptionUpdating(true);
    deleteSubscription(
      {
        subscriptionId: subscription?.subscriptionId || "",
      },
      {
        onSuccess: async () => {
          enqueueSnackbar("Subscription has been cancelled", {
            variant: "success",
          });

          await queryClient.invalidateQueries({
            queryKey: getGetUserQueryKey(),
          });
        },
        onError: (error: unknown) => {
          setIsSubscriptionUpdating(false);
          enqueueSnackbar("Subscription was not cancelled, please try again", {
            variant: "error",
          });
          logger.error({ message: "Error deleting subscription", error });
        },
      },
    );
  };

  const updateSubscription = () => {
    setIsSubscriptionUpdating(true);
    updateToPaidSubscription(
      {
        subscriptionId: subscription?.subscriptionId || "",
      },
      {
        onSuccess: async () => {
          enqueueSnackbar("Subscription has been updated", {
            variant: "success",
          });

          await queryClient.invalidateQueries({
            queryKey: getGetUserQueryKey(),
          });
        },
        onError: (error: unknown) => {
          setIsSubscriptionUpdating(false);
          enqueueSnackbar("Subscription was not updated, please try again", {
            variant: "error",
          });
          logger.error({ message: "Error updating subscription", error });
        },
      },
    );
  };

  const handleManageBilling = () => {
    manageSubscriptionBilling(
      {
        stripeSubscriptionId: subscription?.stripeSubscriptionId || "",
      },
      {
        onSuccess: (data: { data: string }) => {
          const checkoutUrl = data?.data;
          window.location.href = checkoutUrl; // Redirect to the provided URL
        },
        onError: (error: unknown) => {
          enqueueSnackbar(
            "Billing details were not updated, please try again",
            {
              variant: "error",
            },
          );
          logger.error({ message: "Error managing billing", error });
        },
      },
    );
  };

  const pricePearYear = `${formatCurrency(
    appConfig.yearlyMembershipPrice,
  )}/year`;

  return (
    <LoadingWrapper
      visible={
        isCreateSubscriptionPending ||
        isDeletingSubscription ||
        isUpdateSubscriptionPending ||
        isSubscriptionUpdating ||
        isManageBillingPending ||
        isUserPending
      }
    >
      <Container maxWidth={"sm"}>
        {isUserPending ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {subscription?.type === "FREE" && (
                <CurrentPlan
                  type={"Basic"}
                  price={"Free"}
                  trialLeft={null}
                  paymentFailedAt={paymentFailedDate}
                />
              )}
              {subscription && subscription?.type === "TRIAL" && (
                <CurrentPlan
                  type={"Premium"}
                  price={pricePearYear}
                  trialLeft={timeLeft?.days || null}
                  renewsAt={renewDate}
                  cancelsAt={cancelDate}
                  paymentFailedAt={paymentFailedDate}
                />
              )}
              {subscription && subscription?.type === "PREMIUM" && (
                <CurrentPlan
                  type={"Premium"}
                  price={pricePearYear}
                  trialLeft={null}
                  renewsAt={renewDate}
                  cancelsAt={cancelDate}
                  paymentFailedAt={paymentFailedDate}
                />
              )}
            </Grid>

            {subscription &&
              (subscription?.type === "TRIAL" ||
                subscription?.type === "PREMIUM") && (
                <>
                  <Grid item xs={12} mt={1}>
                    {!subscription?.cancelsAt && (
                      <>
                        <Button onClick={handleDelete}>
                          <Typography variant={"link"}>
                            Cancel Subscription
                          </Typography>
                        </Button>

                        <Button onClick={handleManageBilling}>
                          <Typography variant={"link"}>
                            Manage Billing Details
                          </Typography>
                        </Button>
                      </>
                    )}

                    {subscription?.cancelsAt && (
                      <Button onClick={updateSubscription}>
                        <Typography variant={"link"}>
                          Reactivate Subscription
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                  <Grid container spacing={1} mt={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ textAlign: "center", color: "text.grey" }}
                    >
                      <Typography variant={"f1"}>
                        Your current subscription period will last until{" "}
                        {subscription.type === "TRIAL"
                          ? timeLeft?.date
                          : renewDate}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ textAlign: "center", color: "text.grey" }}
                    >
                      <Typography variant={"f1"}>
                        If you cancel anytime on or before that date you will
                        not be charged after your current{" "}
                        {subscription?.type === "TRIAL" ? "trial" : ""}{" "}
                        subscription period ends.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}

            {subscription?.type === "FREE" && subscription?.paymentFailedAt && (
              <Grid item xs={12}>
                <Button onClick={handleManageBilling}>
                  <Typography variant={"link"}>
                    Reactivate Subscription
                  </Typography>
                </Button>
              </Grid>
            )}

            {subscription?.type === "FREE" &&
              !subscription?.paymentFailedAt && (
                <Grid item xs={12}>
                  <BackgroundImageContainer>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        color: "white",
                        zIndex: 1,
                        padding: "32px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant={"h4"}>
                          Upgrade to Premium to see tee times 10 days in
                          advance!
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          <Typography variant={"h3"}>
                            {formatCurrency(appConfig.yearlyMembershipPrice)}
                          </Typography>
                          <Typography sx={{ ml: 1 }}>per user/year</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {isTrialExpired ? (
                          <Button
                            variant={"secondary"}
                            onClick={() =>
                              handleCreateCheckoutSession({ isTrial: false })
                            }
                          >
                            Start your Premium Subscription
                          </Button>
                        ) : (
                          <Button
                            variant={"secondary"}
                            onClick={() =>
                              handleCreateCheckoutSession({ isTrial: true })
                            }
                          >
                            Start a 30-day Premium trial
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </BackgroundImageContainer>
                </Grid>
              )}
          </Grid>
        )}
      </Container>
    </LoadingWrapper>
  );
};
