import {
  RouterLink as Link,
  TextFieldPasswordWithController,
  TextFieldWithController,
} from "@common";
import { Button, Grid, Typography } from "@mui/material";
import { Control, UseFormHandleSubmit } from "react-hook-form";

import * as yup from "yup";
import { emailRegexp } from "@utils";
import { logClickEvent } from "@services/Analytics";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegexp, "Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short"),
});

export type LoginFormValues = yup.InferType<typeof loginSchema>;

export const LoginForm = ({
  onSubmit,
  control,
  errorMessage,
}: {
  onSubmit: ReturnType<UseFormHandleSubmit<LoginFormValues>>;
  control: Control<LoginFormValues>;
  errorMessage: string;
}) => {
  return (
    <Grid container spacing={2} component={"form"} onSubmit={onSubmit}>
      <Grid item xs={12} sx={{ textAlign: "center", mb: { xs: 1, md: 2 } }}>
        <Typography variant={"h3"}>Log In</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithController
          control={control}
          name="email"
          placeholder="Input email"
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldPasswordWithController
          name="password"
          control={control}
          placeholder="Input password"
          label="Password"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Link
          to={"/change-password"}
          onClick={() =>
            logClickEvent({
              label: "login-forgot-password-link",
              category: "navigation",
            })
          }
        >
          <Typography variant={"p2"} sx={{ ml: 1, fontWeight: "bold" }}>
            Forgot your Password?
          </Typography>
        </Link>
      </Grid>

      <Grid item xs={12}>
        <Typography color="error">{errorMessage}</Typography>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center">
        <Button
          type="submit"
          variant="secondary"
          onClick={() => {
            logClickEvent({
              label: "login-login-submit",
              category: "navigation",
            });
          }}
        >
          Log in
        </Button>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" mb={5}>
        <Typography variant={"p2"}>Don't have an account?</Typography>

        <Link
          to={"/create-account"}
          onClick={() =>
            logClickEvent({
              label: "login-create-account-link",
              category: "navigation",
            })
          }
        >
          <Typography variant={"p2"} sx={{ ml: 1, fontWeight: "bold" }}>
            Create Account
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
