import { AxiosError } from "axios";
import React from "react";
import { logger } from "./Logger";
import { useSnackbar } from "notistack";
import { useAppNavigate } from "@services/Navigation";
import { appConfig } from "@services/AppConfig";

const UNKNOWN_ERROR = "An unknown error has occurred. Please try again later.";
export const TOKEN_EXPIRED_ERROR_CODE = "TOKEN_EXPIRED";

const {
  trialUserDaysSearchLimit,
  premiumUserDaysSearchLimit,
  freeUserDaysSearchLimit,
} = appConfig;

// Dictionary of error codes returned from the server and their corresponding error messages
export const errorMessageDictionary = {
  UNKNOWN_ERROR,
  [TOKEN_EXPIRED_ERROR_CODE]: "Your session has expired. Please log in again.",
  FREE_USER_DATE_RANGE_INVALID: `Free users can only search up to ${freeUserDaysSearchLimit} days, upgrade to premium to search up to ${premiumUserDaysSearchLimit} days in advance.`,
  PREMIUM_USER_DATE_RANGE_INVALID: `Premium users can only search up to ${premiumUserDaysSearchLimit} days in advance.`,
  TRIAL_USER_DATE_RANGE_INVALID: `Trial users can only search up to ${trialUserDaysSearchLimit} days in advance.`,
};

export type ERROR_CODE = keyof typeof errorMessageDictionary;

// This is the type that should be returned from the API in the event of an error
export const APP_API_ERROR_TYPE = "TeetimeApiError";

export type AppApiError = {
  code: ERROR_CODE;
  details: string;
  message: string;
  type: "TeetimeApiError";
};
export type AxiosErrorWithAppApiResponse = AxiosError<AppApiError>;

export function getAppApiError(error: unknown): undefined | AppApiError {
  const appAxiosError = error as AxiosErrorWithAppApiResponse;

  const isAppApiError =
    appAxiosError?.response?.data?.type === APP_API_ERROR_TYPE;

  if (appAxiosError && isAppApiError) {
    return appAxiosError?.response?.data;
  }
}

type OnErrorHandler<TError = AppApiError | unknown> = ({
  AppApiError,
  error,
  defaultHandler,
}: {
  AppApiError: ReturnType<typeof getAppApiError>;
  error: TError;
  //  The default handler is provided to onError so that the default error handler can be called if the custom error handler does not handle the error.
  defaultHandler(): void;
}) => void;

type ErrorHandlerProps<TError> = {
  // id is used for logging purposes
  id?: string;
  error: TError;
  onError?: OnErrorHandler<TError>;
};

/**
 * This hook is used to handle errors from the App API. It assumes the API is returning error codes and an error type. It will log the error and display a snackbar message.
 */
function useErrorHandler<TError = AppApiError | unknown>(
  props: ErrorHandlerProps<TError>,
): void {
  const { id, error, onError } = props;

  const appNavigate = useAppNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const defaultOnErrorHandler = React.useCallback<OnErrorHandler>(
    ({ AppApiError, error }) => {
      if (AppApiError && errorMessageDictionary[AppApiError.code]) {
        enqueueSnackbar({
          message: errorMessageDictionary[AppApiError.code],
          variant: "error",
        });

        if (AppApiError.code === TOKEN_EXPIRED_ERROR_CODE) {
          enqueueSnackbar({
            message: errorMessageDictionary.TOKEN_EXPIRED,
            variant: "error",
          });
          appNavigate.toLogin();
        }
      } else {
        logger.error({
          message: `An unknown error encountered`,
          error,
          data: {
            errorId: id,
          },
        });
        enqueueSnackbar({
          message: errorMessageDictionary.UNKNOWN_ERROR,
          variant: "error",
        });
      }
    },
    [],
  );

  React.useEffect(() => {
    if (error) {
      const errorHandler = onError || defaultOnErrorHandler;
      return errorHandler({
        AppApiError: getAppApiError(error),
        error,
        defaultHandler: () => {
          defaultOnErrorHandler({
            AppApiError: getAppApiError(error),
            error,
            defaultHandler: () => {},
          });
        },
      });
    }
  }, [error]);
}

export const useAppErrorHandlers = (
  params: ErrorHandlerProps<AppApiError | unknown>[],
) => params.forEach((param) => useErrorHandler(param));
