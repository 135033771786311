import { queryClient } from "./QueryClient";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useAppErrorHandlers } from "@/services/ErrorMessaging";
import { enqueueSnackbar } from "notistack";
import { atom, useRecoilState } from "recoil";
import {
  getGetAssistantMessagesQueryKey,
  useGetAssistantMessages,
  useGetAssistantRun,
  usePostAssistantMessage,
} from "@thesparklaboratory/teetimeportal-react-query-client";
import { GetAssistantMessages200MessagesItem } from "@thesparklaboratory/teetimeportal-react-query-client/dist/api/teetimeApi.schemas";

const assistanceModalState = atom({
  key: "assistantModalState",
  default: { isOpen: false },
});

export function useAssistantModalState() {
  return useRecoilState(assistanceModalState);
}

export type RunStatus =
  | "queued"
  | "in_progress"
  | "requires_action"
  | "cancelling"
  | "cancelled"
  | "failed"
  | "completed"
  | "expired";

const schema = yup.object().shape({
  message: yup.string().required("Chat text is required"),
});

function createWelcomeMessage({
  content,
}: {
  content: string;
}): GetAssistantMessages200MessagesItem {
  return {
    runId: "",
    messageId: "welcomeMessage",
    content: [content],
    role: "assistant",
  };
}

function createDecoratedMessages({
  messages,
  pendingMessage,
  runStatus,
  welcomeMessage,
  loadingText,
}: {
  threadId?: string;
  messages: GetAssistantMessages200MessagesItem[];
  pendingMessage?: string | null;
  runStatus?: RunStatus;
  welcomeMessage: GetAssistantMessages200MessagesItem;
  loadingText: string;
}) {
  let decoratedMessages = [...messages, welcomeMessage];

  if (pendingMessage) {
    decoratedMessages = [
      {
        runId: "",
        messageId: "pendingUserMessage",
        content: [pendingMessage],
        role: "user",
      },
      ...decoratedMessages,
    ];
  }

  if (
    runStatus === "in_progress" ||
    messages[0]?.role === "user" ||
    pendingMessage
  ) {
    decoratedMessages = [
      {
        runId: "",
        messageId: "assistantMessage",
        content: [loadingText],
        role: "assistant",
      },
      ...decoratedMessages,
    ];
  }

  return decoratedMessages;
}

export function useAssistant({
  inputPlaceholderText,
  loadingText,
  welcomeMessageContent,
  userInitials,
}: {
  initialThreadId?: string;
  welcomeMessageContent: string;
  inputPlaceholderText: string;
  loadingText: string;
  userInitials?: string;
}) {
  const { control, handleSubmit, reset, getValues, setFocus } = useForm({
    resolver: yupResolver(schema),
  });

  const [threadId, setThreadId] = useLocalStorage("threadId", "");
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const {
    mutate: postMessage,
    isSuccess: isPostMessageSuccess,
    isPending: isPostMessagePending,
    error: postMessageError,
    data: postAssistantMessageData,
  } = usePostAssistantMessage();
  const welcomeMessage = createWelcomeMessage({
    content: welcomeMessageContent,
  });
  const [pendingMessage, setPendingMessage] = useState<string | null>(null);
  const { data: assistantMessagesResponse } = useGetAssistantMessages(
    { threadId: threadId || "" },
    {
      query: {
        enabled: !!threadId,
      },
    },
  );

  const messages = assistantMessagesResponse?.data?.messages || [];
  const [runId, setRunId] = useState<string>("");
  const { data: getAssistantRunResponse } = useGetAssistantRun(
    { threadId, runId },
    { query: { enabled: !!threadId && !!runId, refetchInterval: 2000 } },
  );
  const runStatus = getAssistantRunResponse?.data?.run.status;

  useAppErrorHandlers([
    {
      error: postMessageError,
      id: "postMessageError",
    },
  ]);

  useEffect(() => {
    const lastMessage = messages?.find(
      (message) => message.content[0] === pendingMessage,
    );
    if (lastMessage) {
      // Once we have the message we posted we can get rid of it in our state.
      setPendingMessage(null);
    }
  }, [messages]);

  const decoratedMessages = useMemo(() => {
    return createDecoratedMessages({
      threadId,
      messages,
      pendingMessage,
      runStatus,
      welcomeMessage,
      loadingText,
    });
  }, [messages, pendingMessage, runStatus, threadId]);

  function scrollToBottom() {
    // The scrollviewRef may not be available immediately after the component mounts, so we need to wait until it is
    let attempts = 0;
    const intervalId = setInterval(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current?.scrollTo({
          top: scrollViewRef.current?.scrollHeight,
        });
        clearInterval(intervalId);
      } else {
        attempts++;
        if (attempts > 10) {
          clearInterval(intervalId);
        }
      }
    }, 100);

    if (scrollViewRef.current) {
      scrollViewRef.current?.scrollTo({
        top: scrollViewRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  useEffect(scrollToBottom, [decoratedMessages]);

  useEffect(() => {
    if (isPostMessagePending) {
      setPendingMessage(getValues("message"));
      reset();
    } else if (isPostMessageSuccess) {
      setThreadId(postAssistantMessageData?.data?.threadId);
      setRunId(postAssistantMessageData.data?.runId);
    }
  }, [isPostMessageSuccess, isPostMessagePending]);

  useEffect(() => {
    if (runStatus === "completed") {
      void queryClient.invalidateQueries({
        queryKey: getGetAssistantMessagesQueryKey({ threadId }),
      });
      setRunId("");
    }
  }, [runStatus, threadId]);

  const isLoading = isPostMessagePending || !!runId;

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setFocus("message");
      }, 1000);
    }
  }, [isLoading]);

  function onClearThread() {
    enqueueSnackbar("The chat conversation has been cleared.", {
      variant: "success",
    });
    setThreadId("");
  }

  const onSubmit = handleSubmit((values) =>
    postMessage({
      data: {
        threadId,
        content: values.message,
      },
    }),
  );

  // This is a magic number that represents the height of the toolbar and text input. I tried to get this via refs but
  // had some trouble. This is a good candidate for a refactor.
  const toolbarAndTextInputHeight = 160;
  const messageContainerHeight = window.innerHeight - toolbarAndTextInputHeight;

  return {
    control,
    decoratedMessages,
    onClearThread,
    inputPlaceholderText,
    scrollViewRef,
    isLoading,
    userInitials,
    onSubmit,
    messageContainerHeight,
    scrollToBottom,
  };
}
