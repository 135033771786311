import { useGetUser } from "@thesparklaboratory/teetimeportal-react-query-client";
import { useAuthenticatedUser } from "@services/Authentication";
import { useAppErrorHandlers } from "@services/ErrorMessaging";
import { isAfter, parseISO } from "date-fns";

export function useUser() {
  const authenticatedUser = useAuthenticatedUser();
  const useGetUserReturn = useGetUser({
    query: {
      enabled: !!authenticatedUser,
    },
  });
  useAppErrorHandlers([
    {
      error: useGetUserReturn.error,
      id: "useUser",
    },
  ]);

  return useGetUserReturn;
}

export const useOnSubscriptionUpdated = ({
  currentUpdatedAt,
  enabled,
  onSubscriptionUpdated,
}: {
  currentUpdatedAt?: string;
  enabled: boolean;
  onSubscriptionUpdated: () => void;
}) => {
  useGetUser({
    query: {
      enabled,
      refetchInterval: (data) => {
        const newUpdatedAt = data?.state?.data?.data?.subscription?.updatedAt;
        if (!newUpdatedAt || !currentUpdatedAt) return 1000;
        if (isAfter(parseISO(newUpdatedAt), parseISO(currentUpdatedAt))) {
          onSubscriptionUpdated();
          return false;
        }
        return 1000;
      },
    },
  });
};
