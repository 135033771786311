import googleReviewImg from "@assets/images/reviews.jpg";
import { CourseDetails, GoogleReviewCard, TeeTimeFlow } from "@common";
import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import SwipeableViews from "react-swipeable-views";
import useParams from "@/hooks/useParams";
import { useFilter } from "@services/Search";
import { useAppNavigate } from "@services/Navigation";
import PlacesService = google.maps.places.PlacesService;
import PlaceReview = google.maps.places.PlaceReview;
import {
  GetCourse200,
  GetCourse200AllOfTeetimesItem,
} from "@thesparklaboratory/teetimeportal-react-query-client/dist/api/teetimeApi.schemas";

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  dir: string;
};

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const CoursePageTabNavigation = ({
  course,
}: {
  course: GetCourse200;
}) => {
  const { courseId, tabId, secondaryId } = useParams();

  if (!courseId) {
    throw new Error("Course ID is required");
  }

  if (!course) {
    throw new Error("Course data is required");
  }

  const navigate = useAppNavigate();

  const location = useLocation();
  const theme = useTheme();
  const [filter] = useFilter();
  const [reviewData, setReviewData] = useState<PlaceReview[]>([]);
  const [courseImg, setCourseImg] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const tabIndexToRoute = useMemo(
    () => [
      `/course/${courseId}`,
      `/course/${courseId}/details`,
      `/course/${courseId}/reviews`,
    ],
    [courseId],
  );

  const tabIndexToNavigate = useMemo(
    () => [
      () => navigate.toCoursePage(courseId),
      () => navigate.toCourseDetails(courseId),
      () => navigate.toCourseReviews(courseId),
    ],
    [courseId],
  );
  const teeTimeId = useMemo(
    () => (tabId === "booking" ? secondaryId : null),
    [tabId, secondaryId],
  );

  const selectedTeetimeId = useMemo(() => teeTimeId || null, [teeTimeId]);

  useEffect(() => {
    const isBookingTab = tabId === "booking" && Number(secondaryId) >= 0;
    const tabIndex = isBookingTab
      ? 0
      : tabIndexToRoute.indexOf(location?.pathname);
    setTabIndex(tabIndex >= 0 ? tabIndex : 0);
  }, [tabId, secondaryId, location?.pathname, tabIndexToRoute]);

  const teetimes = useMemo(() => {
    if (!course.teetimes) return [];
    return course.teetimes.sort((a, b) => a.date.localeCompare(b.date));
  }, [course, filter]);

  const handleBook = (teeTime: GetCourse200AllOfTeetimesItem | null) => {
    if (teeTime === null && courseId) {
      return navigate.toCoursePage(courseId);
    }
    if (teeTime?.teetimeId === selectedTeetimeId) {
      return;
    }

    if (!teeTime?.teetimeId || !courseId) return;

    return navigate.toBookingPage({ courseId, teetimeId: teeTime.teetimeId });
  };

  const getReviews = ({
    placesService,
    placeId,
  }: {
    placeId: string;
    placesService: PlacesService;
  }) => {
    placesService.getDetails(
      { placeId: placeId, fields: ["reviews", "photos"] },
      (place, status) => {
        if (status === "OK") {
          setReviewData(place?.reviews || []);
          if (place?.photos && place?.photos.length > 0) {
            const url = place.photos[0].getUrl({ maxWidth: 1200 });
            setCourseImg(url);
          }
        }
      },
    );
  };

  const courseNameWithFacility =
    course?.name === course?.facility?.name
      ? course?.name
      : `${course?.facility?.name} - ${course?.name}`;

  const getPlace = () => {
    if (!course) return;

    if (window?.google?.maps) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div"),
      );

      const location =
        course?.facility.address.latitude && course.facility.address.longitude
          ? {
              lat: course.facility.address.latitude,
              lng: course.facility.address.longitude,
            }
          : undefined;

      service.textSearch(
        {
          query: courseNameWithFacility,
          location,
        },
        (place, status) => {
          if (status === "OK") {
            const placeId = place?.[0]?.place_id;
            if (placeId) {
              getReviews({ placesService: service, placeId });
            }
          }
        },
      );
    }
  };

  useEffect(() => {
    getPlace();
  }, [course]);

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          color: theme.palette.grey[700],
          "& .MuiTab-textColorInherit.Mui-selected": {
            color: theme.palette.text.primary,
          },
        }}
      >
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="tabs"
        >
          <Tab
            onClick={() => navigate.toCoursePage(courseId)}
            label="TEE TIMES"
            {...a11yProps(0)}
          />
          <Tab
            onClick={() => navigate.toCourseDetails(courseId)}
            label="COURSE DETAILS"
            {...a11yProps(1)}
          />
          <Tab
            onClick={() => navigate.toCourseReviews(courseId)}
            label="REVIEWS"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabIndex}
        onChangeIndex={(index) => tabIndexToNavigate[index]()}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <Box
            marginTop={2}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {teetimes.map((teeTime, index) => (
              <TeeTimeFlow
                key={index}
                course={course}
                selectedTeetimeId={selectedTeetimeId}
                handleBook={handleBook}
                teetime={teeTime}
              />
            ))}
          </Box>
        </TabPanel>

        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          {course && <CourseDetails img={courseImg} courseData={course} />}
        </TabPanel>

        <TabPanel value={tabIndex} index={2} dir={theme.direction}>
          <Box>
            <Box
              sx={{ "& img": { marginTop: theme.spacing(2), width: "150px" } }}
            >
              <img src={googleReviewImg} alt="Review image" />
            </Box>
            {reviewData &&
              reviewData?.map((review, i: number) => (
                <GoogleReviewCard key={i} {...review} />
              ))}
            {!reviewData && (
              <Typography>No Reviews from Google Available</Typography>
            )}
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
