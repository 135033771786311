import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { appConfig } from "@services/AppConfig";

Sentry.init({
  dsn: appConfig.sentryDsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: appConfig.stage,
});

export default Sentry;
