import TeeTimePortalImg from "@assets/images/teetime-portal-photo.jpg";
import { Button, ImageWithOverlay, SearchBar } from "@common";
import AssistantIcon from "@mui/icons-material/Assistant";
import {
  Container as MuiContainer,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAssistantModalState } from "@services/Assistant";
import { useSearchBar } from "@services/Search";
import { useAppNavigate } from "@services/Navigation";
import { appConfig } from "@services/AppConfig";
import { logClickEvent } from "@services/Analytics";

const { isAssistantEnabled } = appConfig;

export const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(6),
  },
}));

export const Title = styled(MuiTypography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: 34,
    marginBottom: theme.spacing(1),
  },
}));

const SearchPage = () => {
  const [, setAssistantModalState] = useAssistantModalState();
  const navigate = useAppNavigate();

  const searchBar = useSearchBar({
    onSubmit: ({ filter }) => navigate.toResults(filter),
  });

  return (
    <ImageWithOverlay src={TeeTimePortalImg}>
      <Container maxWidth="md">
        <Title variant="h6">
          <b>Search for a tee time</b>
        </Title>
        <SearchBar {...searchBar} />
        {isAssistantEnabled && (
          <Grid container justifyContent="center" direction="row" mt={10}>
            <Grid>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  logClickEvent({
                    category: "call-to-action",
                    label: "search-page-ai-assistant-button",
                  });
                  setAssistantModalState({ isOpen: true });
                }}
              >
                Try our new AI Assistant&nbsp;
                <AssistantIcon />
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </ImageWithOverlay>
  );
};

export default SearchPage;
