import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { RouterLink as Link } from "@common";
import { logClickEvent } from "@services/Analytics";
const PlanCard = styled(Box)({
  background: "#000",
  color: "#fff",
  padding: 24,
  borderRadius: 6,
  minHeight: 300,
  maxWidth: "400px",
});

function JoinSection() {
  return (
    <SectionContainer mb={10}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid sx={{ textAlign: "center" }}>
              <Typography
                variant="p1"
                gutterBottom
                color="green.secondary"
                sx={{ textTransform: "uppercase", textAlign: "center" }}
              >
                Start Free or Upgrade
              </Typography>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  margin: "0 auto",
                  my: 2,
                  width: {
                    xs: "75%",
                  },
                }}
              >
                Enter the Tee Time Portal Today
              </Typography>
              <Typography
                variant={"p2"}
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  width: {
                    xs: "100%",
                    md: "75%",
                  },
                }}
                gutterBottom
                color="text.grey"
              >
                Sign up now for free or upgrade to our Premium Plan to view tee
                times up to 10 days in advance and get immediate local perks!
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
              }}
              ml={{
                xs: 0,
                md: 2.5,
              }}
              mt={{
                xs: 1,
                md: 8,
              }}
            >
              <Grid item xs={12} md={5} style={{ display: "flex" }}>
                <PlanCard
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Basic
                  </Typography>
                  <Typography variant="h3">Free</Typography>
                  <Box my={3}>
                    <Link
                      to={"/create-account"}
                      onClick={() => {
                        logClickEvent({
                          label: "join-section-basic-account",
                          category: "call-to-action",
                        });
                      }}
                    >
                      <Button variant="primary">Create a Basic Account</Button>
                    </Link>
                  </Box>
                  <Divider sx={{ color: "#8B8FA3" }} />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Access to hundreds of golf courses.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            View tee times at courses up to{" "}
                            <Typography
                              variant={"p2"}
                              sx={{ display: "inline-flex" }}
                              color="green.primary"
                            >
                              3
                            </Typography>{" "}
                            days in advance.
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </PlanCard>
              </Grid>
              <Grid item xs={12} md={5} style={{ display: "flex" }}>
                <PlanCard
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Premium
                  </Typography>
                  <Typography variant="h3">
                    $39.99 <Typography variant={"p2"}>per year</Typography>
                  </Typography>
                  <Box my={3}>
                    <Link
                      to={"/create-account?sub=trial"}
                      onClick={() => {
                        logClickEvent({
                          category: "call-to-action",
                          label: "join-section-premium-trial",
                        });
                      }}
                    >
                      <Button variant="secondary">
                        Start a 30-Day Premium Trial
                      </Button>
                    </Link>
                  </Box>
                  <Divider sx={{ color: "#8B8FA3" }} />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Access to hundreds of golf courses.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            View tee times at courses up to{" "}
                            <Typography
                              variant={"p2"}
                              sx={{ display: "inline-flex" }}
                              color="green.primary"
                            >
                              10
                            </Typography>{" "}
                            days in advance.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Complimentary 18-hole round of golf at Legacy Golf
                            (Faribault, MN).
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Get early bird invitations to local golf events
                            sponsored by Tee Time Portal.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Receive invitations to be a local Course Reviewer
                            (play for free).
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant={"p2"}>
                            Additional local perks added throughout the year.
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </PlanCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default JoinSection;
