import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccessTimeFilled from "@mui/icons-material/AccessTimeFilled";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GolfCourseOutlinedIcon from "@mui/icons-material/GolfCourseOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import GradeIcon from "@mui/icons-material/Grade";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ErrorMaterialIcon from "@mui/icons-material/Error";
import CloseMaterialIcon from "@mui/icons-material/Close";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import { Groups as GroupsIcon, Place } from "@mui/icons-material";

export const Pin = LocationOnOutlinedIcon;

export const ClockIcon = ScheduleIcon;
export const ClockIconFilled = AccessTimeFilled;

export const PersonIcon = PersonOutlineOutlinedIcon;

export const GolfIcon = GolfCourseOutlinedIcon;

export const PhoneIcon = PhoneOutlinedIcon;

export const PaceIcon = TimerOutlinedIcon;

export const StaffIcon = PeopleOutlineIcon;

export const WebIcon = PublicOutlinedIcon;

export const MinusIcon = RemoveIcon;

export const PlusIcon = AddIcon;
export const StarIcon = GradeIcon;

export const LogOutIcon = ExitToAppIcon;

export const ErrorIcon = ErrorMaterialIcon;

export const CloseIcon = CloseMaterialIcon;

export const UserIcon = AccountCircleOutlinedIcon;

export const AlarmIcon = AccessAlarmIcon;

export const LocationPinIcon = PinDropOutlinedIcon;
export const LocationFilledPinIcon = Place;

export const User1Icon = PermIdentityIcon;

export const ShareIcon = ShareOutlinedIcon;

export const ShareAltIcon = OpenInBrowserOutlinedIcon;

export const Groups = GroupsIcon;
