import { Counter, DatePickerWithController, Groups, TimeSelect } from "@common";
import { Box, Button, Typography } from "@mui/material";
import { AddressCompleteWithController } from "../input/AddressComplete";
import {
  Container,
  CounterContainer,
  DateContainer,
  GolfersContainer,
  IconLabelContainer,
  Label,
  LocationContainer,
  SearchbarContainer,
  TimeContainer,
} from "./styles";

import { useSearchBar } from "@services/Search";

export const SearchBar = ({
  control,
  handleSubmit,
  filter,
  maxDate,
}: {
  control: ReturnType<typeof useSearchBar>["control"];
  handleSubmit: ReturnType<typeof useSearchBar>["handleSubmit"];
  filter: ReturnType<typeof useSearchBar>["filter"];
  maxDate: ReturnType<typeof useSearchBar>["maxDate"];
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <LocationContainer>
          <Label variant="caption">Location</Label>

          <AddressCompleteWithController
            control={control}
            name="location"
            InputProps={{ sx: { borderRadius: 2 } }}
          />
        </LocationContainer>

        <DateContainer>
          <Label variant="caption">Date</Label>
          <DatePickerWithController
            control={control}
            name="date"
            maxDate={maxDate}
          />
        </DateContainer>

        <TimeContainer>
          <Label variant="caption">Time of Day</Label>
          <TimeSelect name="time" control={control} time={filter?.time} />
        </TimeContainer>

        <GolfersContainer>
          <Label variant="caption">Number of Golfers</Label>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              alignItems: "center",
            }}
          >
            <IconLabelContainer>
              <Groups sx={{ color: "green.primary" }} />
              <Typography sx={{ paddingLeft: "11px" }} variant="f3">
                Number of Golfers
              </Typography>
            </IconLabelContainer>
            <CounterContainer>
              <Counter control={control} name="count" />
            </CounterContainer>
          </Box>
        </GolfersContainer>

        <SearchbarContainer>
          <Button
            type="submit"
            variant="secondary"
            sx={{ flex: 1, display: "flex" }}
          >
            Search
          </Button>
        </SearchbarContainer>
      </Container>
    </form>
  );
};
