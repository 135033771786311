import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";

function CheckoutConfirmationModal({ open }: { open: boolean }) {
  return (
    <Dialog open={open}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
        }}
      >
        <CircularProgress sx={{ color: "green.primary" }} />
        <Typography variant="h6" mt={2} textAlign="center">
          Connecting to our payment partner, Stripe...
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default CheckoutConfirmationModal;
