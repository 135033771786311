import { endOfDay, formatISO, set } from "date-fns";
import { Filter } from "@services/Search";
import { useMemo } from "react";
import { TIME_SLIDER_LATEST_TIME_SELECTABLE } from "@common";

type CourseTeetimeFilterQueryParams = {
  courseId?: string;
  priceMin?: number;
  distanceMax?: number;
  priceMax?: number;
  distanceMin?: number;
  lng?: number;
  dateStart?: string;
  players?: number;
  dateEnd?: string;
  lat?: number;
  holes: number[];
};

function getCourseTeetimeFilterQueryParams(
  filter: Filter,
): CourseTeetimeFilterQueryParams {
  const startingHour = filter?.time?.value[0];
  const isLatestTimeSelectable =
    filter?.time?.value[1] === TIME_SLIDER_LATEST_TIME_SELECTABLE;
  const endingHour = filter?.time?.value[1];

  let filterDate = new Date(filter.date);

  if (filterDate < new Date()) filterDate = new Date();

  const dateStart = set(filterDate, {
    hours: startingHour,
    minutes: 0,
    seconds: 0,
  });

  const dateEnd = isLatestTimeSelectable
    ? endOfDay(filterDate)
    : set(filterDate, {
        hours: endingHour,
        minutes: 0,
        seconds: 0,
      });

  const { lat, lng } = filter?.location?.geocode || {
    lat: undefined,
    lng: undefined,
  };
  const players = filter?.count;
  const [priceMin, priceMaxRaw] = filter?.price || [];
  const priceMax = priceMaxRaw === 100 ? undefined : priceMaxRaw;
  const [distanceMin, distanceMax] = filter?.distance || [];
  const holes = [];

  if (filter?.nineHoles) holes.push(9);
  if (filter?.eighteenHoles) holes.push(18);

  return {
    courseId: filter?.courseId,
    lat,
    lng,
    dateStart: formatISO(dateStart),
    dateEnd: formatISO(dateEnd),
    players,
    priceMin,
    priceMax,
    distanceMin,
    distanceMax,
    holes,
  };
}

export function useTeetimeCourseSummaryFilterQueryParams({
  filter,
}: {
  filter: Filter;
}) {
  return useMemo(() => {
    return getCourseTeetimeFilterQueryParams(filter);
  }, [filter]);
}
