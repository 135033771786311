import { atom, useRecoilState } from "recoil";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import qs from "qs";
import { useUser } from "@services/User";
import { addDays, isAfter } from "date-fns";

import { logEvent } from "@services/Analytics";
import { appConfig } from "@services/AppConfig";
import { GetUser200AllOfSubscription } from "@thesparklaboratory/teetimeportal-react-query-client/dist/api/teetimeApi.schemas";

export type QueryParamFilter = {
  courseId?: string;
  location?: {
    geocode?: {
      lat?: string;
      lng?: string;
    };
    description?: string;
  };
  date?: string;
  time?: {
    alignment?: string;
    value?: string[];
  };
  count?: string;
  price?: string[];
  distance?: string[];
  eighteenHoles?: "true" | "false";
  nineHoles?: "true" | "false";
};

export type Filter = {
  courseId: string;
  location: {
    geocode: {
      lat: number;
      lng: number;
    };
    description: string;
  };
  date: Date;
  time: {
    alignment: string;
    value: number[];
  };
  count: number;
  price: number[];
  distance: number[];
  eighteenHoles: boolean;
  nineHoles: boolean;
};

export const PRICE_MIN = 0;
export const PRICE_MAX = 100;
export const DISTANCE_MIN = 0;
export const DISTANCE_MAX = 50;

export const defaultFilter: Filter = {
  courseId: "",
  location: { geocode: { lat: 0, lng: 0 }, description: "" },
  date: new Date(),
  time: {
    alignment: "Any time",
    value: [6, 18],
  },
  count: 4,
  price: [PRICE_MIN, PRICE_MAX],
  distance: [DISTANCE_MIN, DISTANCE_MAX],
  eighteenHoles: true,
  nineHoles: false,
};

export function getFilterValue<T extends keyof Filter>(filter: Filter, key: T) {
  return filter[key];
}

export function isValidKey(key: string): key is keyof Filter {
  return key in defaultFilter;
}

export function filterToQueryParam(filter: Filter) {
  return qs.stringify(filter, {
    addQueryPrefix: true,
    allowDots: true,
    skipNulls: true,
  });
}

export function queryParamToFilter(search: string): Filter {
  const {
    count,
    date,
    distance,
    eighteenHoles,
    location,
    nineHoles,
    price,
    time,
    courseId = "",
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  }) as unknown as QueryParamFilter;

  const filter: Partial<Filter> = {
    courseId,
  };

  if (
    location &&
    location.description &&
    location.geocode?.lng &&
    location.geocode?.lat
  ) {
    filter.location = {
      description: location.description,
      geocode: {
        lat: Number(location.geocode.lat),
        lng: Number(location.geocode.lng),
      },
    };
  }

  if (date) {
    filter.date = new Date(date);
    if (isAfter(new Date(), filter.date)) {
      filter.date = new Date();
    }
  }

  if (time && time.alignment && time.value) {
    filter.time = {
      alignment: time.alignment,
      value: time.value.map(Number),
    };
  }

  if (count) {
    filter.count = Number(count);
  }

  if (price) {
    filter.price = price.map(Number);
  }

  if (distance) {
    filter.distance = distance.map(Number);
  }

  if (eighteenHoles) {
    filter.eighteenHoles = eighteenHoles === "true";
  }
  if (nineHoles) {
    filter.nineHoles = nineHoles === "true";
  }

  return { ...defaultFilter, ...filter };
}

const filterAtom = atom<Filter>({
  key: "course",
  default: defaultFilter,
});

export function useSyncFilterWithQueryParams() {
  const [filter, setFilter] = useFilter();
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    const parsedFilter = queryParamToFilter(window.location.search);
    setFilter(parsedFilter);
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const parsedFilter = queryParamToFilter(window.location.search);
    if (JSON.stringify(parsedFilter) !== JSON.stringify(filter)) {
      window.history.pushState(
        {},
        "",
        window.location.pathname + filterToQueryParam(filter),
      );
    }
  }, [filter]);
}

export function useFilter() {
  return useRecoilState(filterAtom);
}

export function useFilterQueryParams() {
  const [filter] = useFilter();
  return filterToQueryParam(filter);
}

export const searchSchema = yup.object().shape({
  location: yup
    .object({
      description: yup.string().required("Location is required"),
      geocode: yup.object({
        lat: yup.number().required("Location is required"),
        lng: yup.number().required("Location is required"),
      }),
    })
    .required("Location is required"),
  date: yup.date().required("Date is required"),
  count: yup.number().required("Number of golfers is required"),
  time: yup.object({
    alignment: yup.string().required("Time of day is required"),
    value: yup.array().required("Time of day is required"),
  }),
});

type SearchForm = yup.InferType<typeof searchSchema>;

export function getAddDaysCountBySubscriptionType(
  subscriptionType: GetUser200AllOfSubscription["type"] = "FREE",
) {
  switch (subscriptionType) {
    case "PREMIUM":
      return appConfig.premiumUserDaysSearchLimit;
    case "TRIAL":
      return appConfig.trialUserDaysSearchLimit;
    case "FREE":
    default:
      return appConfig.freeUserDaysSearchLimit;
  }
}

export function useSearchBar({
  onSubmit,
}: {
  onSubmit?: ({ filter }: { filter: Filter }) => void;
} = {}) {
  const [filter, setFilter] = useFilter();
  const { data } = useUser();

  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: {
      location: filter?.location,
      date: filter?.date,
      count: filter?.count,
      time: filter?.time,
    },
  });
  const maxDate = useMemo(() => {
    const user = data?.data;

    const addDaysCount = getAddDaysCountBySubscriptionType(
      user?.subscription?.type,
    );

    return addDays(new Date(), addDaysCount);
  }, [data?.data]) as Date;

  useEffect(() => {
    if (errors.location) {
      enqueueSnackbar("Please enter a location to search", {
        variant: "error",
      });
    }
  }, [errors.location]);

  function _onSubmit(searchForm: SearchForm) {
    const newFilter = { ...defaultFilter, ...filter, ...searchForm };
    setFilter(newFilter);
    logEvent("search", {
      date: newFilter.date,
      priceMin: newFilter.price[0],
      priceMax: newFilter.price[1],
      distanceMin: newFilter.distance[0],
      distanceMax: newFilter.distance[1],
      eighteenHoles: newFilter.eighteenHoles,
      nineHoles: newFilter.nineHoles,
      numberOfGolfers: newFilter.count,
      timeStart: newFilter.time.value[0],
      timeEnd: newFilter.time.value[1],
    });
    if (onSubmit) {
      onSubmit({ filter: newFilter });
    }
  }

  return { control, handleSubmit: handleSubmit(_onSubmit), filter, maxDate };
}
