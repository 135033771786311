import { Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { ClockIconFilled, GolfIcon } from "@common";
import { CalendarIcon } from "@mui/x-date-pickers";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  textAlign: "left",
  padding: theme.spacing(2.5),
  borderRadius: "4px",
  [theme.breakpoints.up("md")]: {
    minHeight: "300px",
  },
}));

function AboutSection() {
  const featureList = [
    {
      title: "All the Best Courses",
      description:
        "We search hundreds of courses including Championship 18 hole courses to 9 hole munis then show you all the options.",
      icon: (
        <Box color="green.primary">
          <GolfIcon sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
    {
      title: "Time-Saving Convenience",
      description:
        "Spend less time searching across multiple sites and more teeing it up plus find some new hidden gems.",
      icon: (
        <Box color="green.primary">
          <ClockIconFilled sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
    {
      title: "Join the Tee Time Portal Community",
      description:
        "Free and Premium Members also get additional local perks and invitations to play in fun local events for Big Fun & Big Prizes.",
      icon: (
        <Box color="green.primary">
          <CalendarIcon sx={{ fontSize: 40 }} />
        </Box>
      ),
    },
  ];

  return (
    <SectionContainer>
      <Container>
        <Grid container>
          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <Typography
                variant="p1"
                gutterBottom
                color="green.secondary"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                About Us
              </Typography>
            </Grid>
            <Grid xs={12} md={8} item>
              <Typography variant="h2" gutterBottom>
                Tired of having to search multiple course websites to find your
                next tee time?
              </Typography>
              <Typography variant="p2" gutterBottom color="grey.600">
                Now it's as simple as choosing your preferred day and time then
                Tee Time Portal searches tons of great courses and shows you
                what's available in one convenient click. Search up to 3 days
                advance tee times for free or upgrade to Premium to display up
                to 10 days and get great local perks.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4} mt={2}>
            {featureList.map((feature, index) => (
              <Grid sm={4} key={index} item>
                <Item>
                  {feature.icon}
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Box mt={1.5}>
                    <Typography variant="p2" color={"text.grey"}>
                      {feature.description}
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default AboutSection;
