import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";
import { RouterLink as Link } from "@common";
import premiumBgImg from "@assets/images/premium-bg.png";
import { logClickEvent } from "@services/Analytics";

const ImageWrapper = styled(Box)({
  width: "100%",
  height: "600px",
  backgroundImage: `url(${premiumBgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.65)", // Adjust the opacity as needed
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
function MembershipSection() {
  return (
    <SectionContainer>
      <Container>
        <ImageWrapper>
          <Grid
            container
            spacing={2}
            sx={{
              textAlign: "center",
              color: "white",
              zIndex: 1,
              position: "relative",
              width: {
                xs: "100%",
                md: "75%",
              },
              padding: {
                xs: "32px",
              },
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="p1"
                color="green.primary"
                sx={{ textTransform: "uppercase" }}
              >
                Start Free or Upgrade
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">
                Upgrade to Tee Time Portal “Premium” to view tee times up to 10
                days in advance and more!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p1">
                As a Tee Time Portal Premium member, enjoy the benefits of
                viewing tee times up to 10 days in advance as well as additional
                perks and early invitations to fun local events sponsored by Tee
                Time Portal.
              </Typography>
            </Grid>

            <Grid item xs={12} mt={3}>
              <Link
                to={"/create-account?sub=trial"}
                onClick={() => {
                  logClickEvent({
                    category: "call-to-action",
                    label: "membership-section-premium-trial",
                  });
                }}
              >
                <Button variant="primary">Start a 30-Day Premium Trial</Button>
              </Link>
            </Grid>
          </Grid>
        </ImageWrapper>
      </Container>
    </SectionContainer>
  );
}

export default MembershipSection;
