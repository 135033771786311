import { Box, Typography, useTheme } from "@mui/material";
import { UserIcon, StarIcon } from "@common";
import PlaceReview = google.maps.places.PlaceReview;

export const GoogleReviewCard = ({
  profile_photo_url,
  author_name,
  rating,
  relative_time_description,
  text,
}: PlaceReview) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", margin: theme.spacing(2.5, 0) }}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box
          sx={{
            "& img": {
              fontSize: theme.spacing(4),
              marginRight: theme.spacing(1),
              color: theme.palette.background.default,
              width: "43px",
              height: "43px",
              objectFit: "cover",
              borderRadius: "50%",
            },
          }}
        >
          {profile_photo_url ? (
            <img src={profile_photo_url} alt="" />
          ) : (
            <UserIcon />
          )}
        </Box>
        <Box>
          <Typography variant="subtitle2">{author_name}</Typography>
        </Box>
      </Box>
      <Box mt={1} display="flex" alignItems="center">
        <StarIcon
          sx={{
            color: "#FBBC04",
            marginRight: theme.spacing(0.2),
            fontSize: theme.spacing(2.4),
          }}
        />
        <Typography variant="caption">
          <b>{rating && rating * 2} </b>
        </Typography>
        <Typography variant="caption" sx={{ marginLeft: theme.spacing(1) }}>
          {relative_time_description}
        </Typography>
      </Box>
      <Typography>{text}</Typography>
    </Box>
  );
};
