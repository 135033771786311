import { Paper } from "@mui/material";
import Markdown from "react-markdown";

export function AssistantMessage({ message }: { message: string }) {
  return (
    <Paper sx={{ p: 4, minWidth: 200 }}>
      <Markdown>{message}</Markdown>
    </Paper>
  );
}
