import Grid from "../common/layout/Grid";
import { Avatar } from "@mui/material";

export function UserMessage({
  message,
  userInitials,
}: {
  message: string;
  userInitials?: string;
}) {
  return (
    <Grid container spacing={2}>
      <Grid>
        <Avatar sx={{ backgroundColor: "secondary.main" }}>
          {userInitials}
        </Avatar>
      </Grid>
      <Grid alignSelf="center">{message}</Grid>
    </Grid>
  );
}
