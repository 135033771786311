import Grid from "../common/layout/Grid";
import { ChatMessage } from "./ChatMessage";
import { GetAssistantMessages200MessagesItem } from "@thesparklaboratory/teetimeportal-react-query-client/dist/api/teetimeApi.schemas";

export function MessageContainer({
  messages,
  userInitials,
}: {
  messages: GetAssistantMessages200MessagesItem[];
  userInitials?: string;
}) {
  return (
    <Grid container>
      <Grid>
        <Grid container direction="column">
          {[...messages].reverse().map((message, index) => (
            <Grid key={message.messageId}>
              <ChatMessage
                key={index}
                message={message.content.join("")}
                role={message.role}
                userInitials={userInitials}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
