import { MinusIcon, PlusIcon } from "@/components/common/Icons";
import { Box, IconButton, Input as MuiInput } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

const Input = styled(MuiInput)(({ theme }) => ({
  width: "50px",
  fontSize: theme.spacing(2.5),
  "& .MuiInput-input": {
    textAlign: "center",
  },
  "&.Mui-disabled:before": {
    borderBottom: "none",
  },
}));

type CounterProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  min?: number;
  max?: number;
};

export function Counter<T extends FieldValues>({
  name,
  min = 1,
  max = 4,
  control,
}: CounterProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginLeft: "12px",
          }}
        >
          <IconButton
            disabled={value <= min}
            onClick={() => onChange(value - 1)}
            sx={{
              width: "24px",
              height: "24px",
              borderWidth: 2,
              borderColor: "grey.600",
              borderStyle: "solid",
            }}
          >
            <MinusIcon sx={{ color: "grey.600" }} />
          </IconButton>

          <Input name={name} value={value} disabled />

          <IconButton
            disabled={value >= max}
            onClick={() => onChange(value + 1)}
            sx={{
              width: "24px",
              height: "24px",
              borderWidth: 2,
              borderColor: "green.primary",
              borderStyle: "solid",
            }}
          >
            <PlusIcon sx={{ color: "green.primary" }} />
          </IconButton>
        </Box>
      )}
    />
  );
}
