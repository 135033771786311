import { Container, Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SectionContainer } from "@pages/LandingPage/styles";

interface NumberCircleProps {
  last?: boolean;
}

const NumberCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "last",
})<NumberCircleProps>(({ theme, last }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "#000",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.125rem",
  fontWeight: "bold",
  position: "relative",
  "&&:before": !last && {
    content: "''",
    position: "absolute",
    bottom: -100,
    width: "1px",
    height: "100px",
    border: "1px dashed #8B8FA3",
  },
  [theme.breakpoints.up("md")]: {
    height: "68px",
    width: "68px",
    fontSize: "2rem",
  },
}));

function HowItWorksSection() {
  return (
    <SectionContainer>
      <Grid
        sx={{
          backgroundColor: "#f4f4f4",
          width: "100%",
          padding: {
            xs: "60px 10px 50px",
            md: "120px 0 110px",
          },
        }}
      >
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography
                variant={"p1"}
                gutterBottom
                color="green.secondary"
                sx={{ textTransform: "uppercase" }}
              >
                How It Works
              </Typography>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                Book tee times easily with just a few steps
              </Typography>
              <Box sx={{ paddingRight: { md: 8 } }}>
                <Typography variant={"p2"} color={"text.grey"}>
                  Experience the simplicity of Tee Time Portal’s streamlined
                  booking process. With extended visibility, you can plan ahead,
                  make confident decisions, and secure your preferred tee times
                  seamlessly.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container flexDirection={"column"}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2}>
                      <NumberCircle>1</NumberCircle>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h3" gutterBottom>
                        Search
                      </Typography>
                      <Typography variant={"p2"} color={"grey.600"}>
                        Enter your location, preferred date, and time to explore
                        a variety of tee time options.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection={"column"}
                  sx={{
                    mt: {
                      xs: 2,
                      md: 7,
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2}>
                        <NumberCircle>2</NumberCircle>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="h3" gutterBottom>
                          Compare
                        </Typography>
                        <Typography variant={"p2"} color={"grey.600"}>
                          Browse through the available tee times, compare
                          prices, and find the best fit for your schedule.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    flexDirection={"column"}
                    sx={{
                      mt: {
                        xs: 2,
                        md: 7,
                      },
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={2}>
                          <NumberCircle last>3</NumberCircle>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="h5" gutterBottom>
                            Book
                          </Typography>
                          <Typography variant={"p2"} color={"grey.600"}>
                            Once you've found the perfect tee time, Tee Time
                            will connect you directly with the course to book.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </SectionContainer>
  );
}
export default HowItWorksSection;
