import { Box, InputLabel, Slider as MaterialSlider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Control, Controller } from "react-hook-form";

const Label = styled(InputLabel)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "left",
  marginBottom: theme.spacing(0.5),
}));

const SliderBasic = styled(MaterialSlider)({
  width: "100%",
  "& .MuiSlider-mark": {
    fontWeight: "bold",
  },
});

type SliderBasicProps = {
  name: string;
  control: Control;
  title: string;
  step?: number;
  defaultValue?: number;
  marks?: boolean;
  min: number;
  max: number;
  [x: string]: unknown;
};

export const Slider = ({
  title,
  step = 1,
  defaultValue,
  marks,
  min,
  control,
  name,
  max,
  ...props
}: SliderBasicProps) => {
  return (
    <Box {...props}>
      <Label>{title}</Label>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <SliderBasic
            {...{ marks, step, defaultValue, min, max }}
            value={value}
            getAriaValueText={(v) => v.toString()}
            aria-labelledby={`${title}-slider`}
            valueLabelDisplay="auto"
            onChange={(_, value) => {
              onChange(value);
            }}
          />
        )}
      />
    </Box>
  );
};
