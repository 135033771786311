import { Box, Typography } from "@mui/material";
import { ErrorIcon, CloseIcon } from "./Icons";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  background: "#ffeff5",
  borderWidth: theme.spacing(0.07),
  borderStyle: "solid",
  borderColor: theme.palette.error.main,
  borderRadius: 2,
}));

const IconError = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.spacing(2),
  marginRight: theme.spacing(1),
}));

const IconClose = styled(CloseIcon)(({ theme }) => ({
  fontSize: theme.spacing(1.2),
  marginLeft: theme.spacing(1),
  cursor: "pointer",
}));

type ErrorBlockProps = {
  className?: string;
  title: string;
  description: string;
  onClose?: () => void;
};

export const ErrorBlock = ({
  className,
  title,
  description,
  onClose,
}: ErrorBlockProps) => {
  return (
    <Container className={className}>
      <Box display="flex">
        <IconError />
        <Box>
          <Typography
            sx={{
              fontSize: ({ spacing }) => spacing(1.3),
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontSize: ({ spacing }) => spacing(1.1) }}>
            {description}
          </Typography>
        </Box>
      </Box>
      {onClose && <IconClose onClick={onClose} />}
    </Container>
  );
};
