import { logEvent as logFirebaseEvent } from "firebase/analytics";
import { getFirebaseAnalytics } from "@services/Firebase";
import { EventParams } from "@firebase/analytics";
import { appConfig } from "@services/AppConfig";

type AnalyticsEventParams = EventParams;

export function logEvent(
  eventName: string,
  eventParams?: AnalyticsEventParams,
) {
  const analytics = getFirebaseAnalytics();
  logFirebaseEvent(analytics, eventName, eventParams);
}

export function logBeginCheckoutEvent(
  eventParams: Omit<AnalyticsEventParams, "items" | "currency"> & {
    items: EventParams["items"];
    value: EventParams["value"];
  },
) {
  logEvent("begin_checkout", { currency: "USD", ...eventParams });
}

export function logPremiumTrialCheckoutEvent({
  productId,
}: {
  productId: string;
}) {
  logBeginCheckoutEvent({
    items: [
      {
        item_id: productId,
        item_name: "Premium Trial",
        quantity: 1,
        price: appConfig.yearlyMembershipPrice,
      },
    ],
    value: appConfig.yearlyMembershipPrice,
  });
}

export function logClickEvent({
  label,
  category,
}: {
  label: string;
  category: "navigation" | "call-to-action" | "support" | "share";
}) {
  logEvent("click", {
    event_label: label,
    event_category: category,
  });
}
