import { Button, Container, Grid, Typography } from "@mui/material";
import SuccessCircle from "@assets/images/success-circle.svg";
import { RouterLink as Link } from "@common";

export const CancelPage = () => {
  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <img src={SuccessCircle} alt={"success-circle"} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={"h3"} gutterBottom>
            You can upgrade anytime!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth={"sm"}>
            <Typography variant={"p2"} color={"text.grey"} gutterBottom>
              Your account has been created at the Basic level. You can upgrade
              your account to Premium (and start your 30-day free trial) anytime
              in your Account.
            </Typography>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Link to={"/"}>
            <Button variant={"secondary"}>Find a tee time</Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};
