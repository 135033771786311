import { useEffect, useState } from "react";

import {
  Button,
  CoursePageTabNavigation,
  LoadingScreen,
  ShareButton,
} from "@common";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { getDistanceFromLatLonInMiles } from "@utils";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useAppNavigate } from "@services/Navigation";
import { useFilter } from "@services/Search";
import { Place } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { logClickEvent } from "@services/Analytics";
import { useGetCourse } from "@thesparklaboratory/teetimeportal-react-query-client";
import { startOfDay, endOfDay } from "date-fns";

const Underline = styled(Typography)({
  fontWeight: 700,
  fontSize: "14px",
  textDecoration: "underline",
});
const CoursePage = () => {
  const { courseId = "" } = useParams();
  const [filter] = useFilter();
  const { data: getCourseResponse, isLoading } = useGetCourse({
    ...filter,
    dateStart: startOfDay(filter?.date).toISOString(),
    dateEnd: endOfDay(filter?.date).toISOString(),
    courseId,
  });
  const course = getCourseResponse?.data;

  const [distance, setDistance] = useState("");

  const theme = useTheme();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (course && course?.facility && filter && filter.location) {
      const {
        facility: {
          address: { latitude: courseLat, longitude: courseLng },
        },
      } = course;

      const { lat: locationLat, lng: locationLng } =
        filter?.location?.geocode || {};
      if (courseLat && courseLng && locationLat && locationLng) {
        const distance = getDistanceFromLatLonInMiles(
          courseLat,
          courseLng,
          locationLat,
          locationLng,
        ).toFixed(2);

        setDistance(distance);
      }
    }
  }, [course, filter]);

  const courseNameWithFacility =
    course?.name === course?.facility?.name
      ? course?.name
      : `${course?.facility?.name} - ${course?.name}`;

  return (
    <LoadingScreen isLoading={isLoading}>
      <Box sx={{ flex: 1, overflow: "hidden", position: "relative" }}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">
              {filter?.location?.description &&
                `${filter.location?.description}, `}
              {filter?.date && `${format(filter?.date, "EEEE, LLL d")}, `}
              {filter?.time && `${filter?.time?.alignment}, `}
              {filter?.count && `${filter?.count} Golfers`}
            </Typography>
            <Button
              onClick={() => {
                logClickEvent({
                  label: "course-page-change-search",
                  category: "navigation",
                });
                navigate.toResults();
              }}
              variant="text"
              color="primary"
              sx={{ color: theme.palette.primary.main }}
            >
              <Underline>Change</Underline>
            </Button>
          </Box>
          <Typography sx={{ fontSize: theme.spacing(2.2), fontWeight: "bold" }}>
            {course && courseNameWithFacility}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex">
              {distance && (
                <>
                  <Place sx={{ color: "green.primary" }} />
                  <Typography>{distance} miles</Typography>
                </>
              )}
            </Box>
            <ShareButton />
          </Box>
          {course && <CoursePageTabNavigation course={course} />}
        </Container>
      </Box>
    </LoadingScreen>
  );
};

export default CoursePage;
