import { Box, Paper as MuiPaper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FilterPaper = styled(MuiPaper)(({ theme }) => ({
  position: "absolute",
  borderRadius: theme.spacing(0.5),
  width: "90vw",
  marginTop: 5,
  left: "50%",
  marginLeft: "-45vw",
}));

export const ErrorBlockContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "max-content",
    margin: `${theme.spacing(1)}px auto`,
  },
}));
