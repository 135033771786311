import { CourseResults } from "@/types/course";
import { differenceInHours } from "date-fns";

export const getRatingWord = (num: number) => {
  if (num < 6) {
    return "Fair";
  } else if (num < 8) {
    return "Good";
  } else if (num < 9) {
    return "Very Good";
  } else if (num <= 10) {
    return "Excellent";
  } else {
    return "";
  }
};
export const passwordErrorMessage =
  "Must contain a lowercase, uppercase, and number, and a special character (!@#$%&*)";
export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*]).*$/;
export const emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$";
export const emailRegexp = new RegExp(emailPattern);

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInMiles = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 3958.8; // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in miles
  return R * c;
};

export const getTeeTimeSummary = (
  startDate: Date,
  endDate: Date,
  course: CourseResults,
) => {
  const hourDiff = differenceInHours(endDate, startDate);
  const displayHourInterval = hourDiff >= 12 ? 3 : hourDiff < 6 ? 1 : 2;
  const display = [];
  for (let i = 0; i < 12 && i < hourDiff; i += displayHourInterval) {
    let hasTimes = false;
    for (let j = i; j < i + displayHourInterval; j++) {
      hasTimes = Boolean(course?.teetimes[j]);
      if (hasTimes) {
        break;
      }
    }
    let hour = startDate?.getHours() + i;
    if (hour > 23) {
      hour -= 24;
    } else if (hour > 12) {
      hour -= 12;
    }
    display.push([hour, hasTimes]);
  }
  return display;
};
