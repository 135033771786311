import SearchSection from "@pages/LandingPage/SearchSection";
import AboutSection from "@pages/LandingPage/AboutSection";
import MembershipSection from "@pages/LandingPage/MembershipSection";
import AiSection from "@pages/LandingPage/AiSection";
import HowItWorksSection from "@pages/LandingPage/HowItWorksSection";
import JoinSection from "@pages/LandingPage/JoinSection";

function LandingPage() {
  return (
    <>
      <SearchSection />
      <AboutSection />
      <MembershipSection />
      <AiSection />
      <HowItWorksSection />
      <JoinSection />
    </>
  );
}

export default LandingPage;
