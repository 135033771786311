import { appConfig } from "@services/AppConfig";
import {
  setBaseUrl,
  axios,
} from "@thesparklaboratory/teetimeportal-react-query-client/dist/client";
import { TOKEN_EXPIRED_ERROR_CODE } from "@services/ErrorMessaging";
import { getFirebaseAuth } from "@services/Firebase";

export function initializeApi() {
  setBaseUrl(appConfig.baseUrl);
  axios.interceptors.request.use(async function (config) {
    const idToken = await getFirebaseAuth()?.currentUser?.getIdToken();
    if (idToken) {
      config.headers.authorization = `Bearer ${idToken}`;
    }

    return config;
  });

  axios.interceptors.response.use(async function (response) {
    if (response?.data?.code === TOKEN_EXPIRED_ERROR_CODE) {
      // If the token is expired, get a new one and force the refresh
      await getFirebaseAuth()?.currentUser?.getIdToken(true);
    }
    return response;
  });
}
