import { Container, Grid } from "@mui/material";
import { useState } from "react";
import { useFilter } from "@services/Search";
import {
  DistanceSelect,
  HoleSelect,
  PriceSelect,
} from "@pages/ResultsPage/FilterPopper";

export const MobileResultsFilter = () => {
  const [filterPaper, openFilterPaper] = useState<
    "holes" | "distance" | "price" | ""
  >("");
  const [filter] = useFilter();
  const [holes, setHoles] = useState({
    eighteenHoles: filter?.eighteenHoles,
    nineHoles: filter?.nineHoles,
  });
  const [price, setPrice] = useState(filter?.price);
  const [distance, setDistance] = useState(filter?.distance);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={3.5}>
          <HoleSelect
            holes={holes}
            setEighteenHoles={(val: boolean) =>
              setHoles({ ...holes, eighteenHoles: val })
            }
            setNineHoles={(val: boolean) =>
              setHoles({ ...holes, nineHoles: val })
            }
            isOpen={filterPaper === "holes"}
            setOpen={() =>
              openFilterPaper(filterPaper === "holes" ? "" : "holes")
            }
          />
        </Grid>
        <Grid item xs={4}>
          <PriceSelect
            isOpen={filterPaper === "price"}
            setOpen={() =>
              openFilterPaper(filterPaper === "price" ? "" : "price")
            }
            setPrice={(val) => setPrice(val)}
            price={price}
          />
        </Grid>
        <Grid item xs={4.5}>
          <DistanceSelect
            distance={distance}
            setDistance={(val) => setDistance(val)}
            isOpen={filterPaper === "distance"}
            setOpen={() =>
              openFilterPaper(filterPaper === "distance" ? "" : "distance")
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};
