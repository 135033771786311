import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Container = styled(Box)({
  flex: 1,
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 2,
  height: "100%",
  width: "100%",
});

const OverlayImage = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  height: "100%",
  width: "100%",
});

const InnerContainer = styled(Box)({
  position: "relative",
  zIndex: 3,
});

type Props = {
  src?: string;
  children?: React.ReactNode;
};

export const ImageWithOverlay = ({ src = "", children }: Props) => (
  <Container>
    <Overlay />
    <OverlayImage src={src} alt="overlay-image" />
    <InnerContainer>{children}</InnerContainer>
  </Container>
);
