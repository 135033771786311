import { LoadingWrapper } from "@/components/common/LoadingWrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import {
  UpdateAccountForm,
  UpdateAccountFormValues,
  updateAccountSchema,
} from "@pages/AccountPage/UpdateUserForm";
import { logger } from "@services/Logger";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { SyntheticEvent, useEffect, useState } from "react";
import { SubscriptionTab } from "@pages/AccountPage/SubscriptionTab";
import {
  getGetUserQueryKey,
  useUpdateUser,
} from "@thesparklaboratory/teetimeportal-react-query-client";
import { Subscription } from "@/types/subscription";
import { useUser } from "@services/User";
import { queryClient } from "@services/QueryClient";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";
import { useAuthenticatedUser } from "@services/Authentication";
import EmailChangeModal from "@pages/AccountPage/components/LogoutModal";
import { useAppNavigate } from "@services/Navigation";

export const AccountPage = () => {
  const query = useURLSearchParams();
  const navigator = useAppNavigate();
  const isSubscription = query.get("page") === "subscription";
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [value, setValue] = useState(
    isSubscription ? "subscription" : "profile",
  );
  const authenticatedUser = useAuthenticatedUser();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { data: userResponse } = useUser();
  const user = userResponse?.data;
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateUser, isPending: isUpdatePending } = useUpdateUser({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar("Profile has been updated", {
          variant: "success",
        });
      },
      onError: (error) => {
        enqueueSnackbar("Profile was not updated, please try again", {
          variant: "error",
        });
        logger.error({ message: "Error updating user", error });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: getGetUserQueryKey() });
      },
    },
  });

  const getUpdateUserDefaultValues = () => {
    const city = user?.address?.city || "";
    const region = user?.address?.region || "";
    return {
      first: user?.firstName || "",
      last: user?.lastName || "",
      email: user?.email || "",
      location: {
        description: city && region ? `${city}, ${region}` : "",
        geocode: { lat: 0, lng: 0 },
        address: {
          city: city || "",
          region: region || "",
        },
      },
    };
  };

  const updateAccount = useForm({
    resolver: yupResolver(updateAccountSchema),
    defaultValues: getUpdateUserDefaultValues(),
  });

  useEffect(() => {
    if (user) {
      updateAccount.reset(getUpdateUserDefaultValues());
    }
  }, [user]);

  const handleUpdateUserSubmit = updateAccount.handleSubmit(
    (updateAccountForm: UpdateAccountFormValues) => {
      updateUser(
        {
          data: {
            email: updateAccountForm.email,
            address: {
              ...updateAccountForm.location.address,
              latitude: updateAccountForm.location.geocode.lat,
              longitude: updateAccountForm.location.geocode.lng,
            },
            firstName: updateAccountForm.first,
            lastName: updateAccountForm.last,
          },
        },
        {
          onSuccess: async () => {
            if (authenticatedUser?.email !== updateAccountForm.email) {
              setShowLogoutModal(true);
            }
          },
        },
      );
    },
  );

  return (
    <>
      <EmailChangeModal
        open={showLogoutModal}
        handleClose={() => navigator.toLogin()}
      />
      <LoadingWrapper visible={isUpdatePending}>
        <Box flex={1} mt={9}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{
                bgcolor: "green.primary",
                color: "text.primary",
                width: 100,
                height: 100,
              }}
            >
              <Typography variant={"h2"}>
                {user?.firstName?.charAt(0)?.toUpperCase()}
                {user?.lastName?.charAt(0)?.toUpperCase()}
              </Typography>
            </Avatar>
          </Box>
          <Typography variant={"h3"} align={"center"} gutterBottom>
            {user?.firstName} {user?.lastName}
          </Typography>

          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                ".MuiTabs-flexContainer": { justifyContent: "center" },
                ".MuiButtonBase-root": {
                  textTransform: "none",
                  color: "text.grey",
                },
                ".Mui-selected": {
                  color: "text.primary",
                },
              }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Profile" value="profile" />
                <Tab label="Subscription" value="subscription" />
              </TabList>
            </Box>
            <TabPanel value={"profile"}>
              <Container maxWidth="sm">
                <UpdateAccountForm
                  onSubmit={handleUpdateUserSubmit}
                  control={updateAccount.control}
                />
              </Container>
            </TabPanel>
            <TabPanel value={"subscription"}>
              <SubscriptionTab
                subscription={
                  (user?.subscription as unknown as Subscription) || {
                    type: "FREE",
                  }
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </LoadingWrapper>
    </>
  );
};

export default AccountPage;
