import { Grid, LoadingScreen, SearchBar } from "@common";
import { Container } from "@mui/material";
import { useTeetimeCourseSummaryFilterQueryParams } from "@services/Courses";
import { Results } from "./Results";
import { useSearchBar } from "@services/Search";
import { useAppNavigate } from "@services/Navigation";
import { BackgroundImageContainer } from "@pages/AccountPage/SubscriptionTab";
import searchBg from "@assets/images/search-bg.png";
import { useGetCourseTeetimeSummary } from "@thesparklaboratory/teetimeportal-react-query-client";
import { useAppErrorHandlers } from "@services/ErrorMessaging";

const ResultsPage = () => {
  const navigate = useAppNavigate();
  const searchBar = useSearchBar({
    onSubmit: ({ filter }) => navigate.toResults(filter),
  });

  const params = useTeetimeCourseSummaryFilterQueryParams({
    filter: searchBar.filter,
  });
  const {
    data: courseTeetimeSummaryResponse,
    isLoading,
    error,
  } = useGetCourseTeetimeSummary(params);

  useAppErrorHandlers([
    {
      error,
      id: "ResultsPage",
    },
  ]);
  return (
    <LoadingScreen isLoading={isLoading}>
      <Grid container spacing={6} mt={0}>
        <BackgroundImageContainer
          sx={{
            backgroundPosition: "top",
            backgroundImage: `url(${searchBg})`,
          }}
        >
          <Container sx={{ zIndex: 1, flex: 1 }}>
            <Grid xs={12} md={10}>
              <SearchBar {...searchBar} />
            </Grid>
          </Container>
        </BackgroundImageContainer>
        <Grid xs={12}>
          <Results courses={courseTeetimeSummaryResponse?.data || []} />
        </Grid>
      </Grid>
    </LoadingScreen>
  );
};

export default ResultsPage;
