import { Link, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import Logo from "@assets/images/logo.svg";
import { Grid } from "@/components/common";
import { appConfig } from "@services/AppConfig";

export function ErrorPage({ eventId }: { eventId: string }) {
  return (
    <Grid
      container
      alignContent="center"
      direction="column"
      flex={1}
      mt={20}
      sx={{
        textAlign: "center",
      }}
    >
      <Grid mb={20}>
        <img src={Logo} alt="TeeTimePortal" />
      </Grid>
      <Grid>
        <ErrorIcon sx={{ fontSize: 100 }} color="error" />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
      >
        <Grid xs={7}>
          <Typography variant="body1">
            Sorry! An unexpected error occurred. A report has been sent to our
            team and we will look into it as soon as possible. If the issue
            persists please contact us at{" "}
            <Link
              href={`mailto:${appConfig.supportEmail}?subject=Unexpected error: ${eventId}`}
            >
              {import.meta.env.VITE_APP_SUPPORT_EMAIL}
            </Link>
            .
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body2" lineHeight={2}>
            Event ID: {eventId}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1">
            <Link href="/">Return to home page</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
