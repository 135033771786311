import { RangeSlider } from "@common";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useFilter } from "@services/Search";

export const DesktopResultsFilter = () => {
  const [filter, setFilter] = useFilter();

  return (
    <>
      <Stack>
        <Typography variant="subtitle2">Number of Holes</Typography>
        <FormControlLabel
          label="18 holes"
          control={
            <Checkbox
              name="eighteenHoles"
              checked={filter?.eighteenHoles}
              onChange={(e) =>
                setFilter({ ...filter, eighteenHoles: e.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label="9 holes"
          control={
            <Checkbox
              name="nineHoles"
              checked={filter?.nineHoles}
              onChange={(e) =>
                setFilter({ ...filter, nineHoles: e.target.checked })
              }
            />
          }
        />
      </Stack>

      <Stack mt={2}>
        <Typography variant="subtitle2" gutterBottom>
          Price
        </Typography>
        <Box maxWidth={250} mt={5}>
          <RangeSlider
            max={100}
            min={0}
            name="price"
            handleChangeCommitted={(newValue) => {
              setFilter({ ...filter, price: newValue as number[] });
            }}
            units="$"
            defaultValue={filter?.price}
          />
        </Box>
      </Stack>

      <Stack mt={2}>
        <Typography variant="subtitle2" gutterBottom>
          Distance
        </Typography>
        <Box maxWidth={250} mt={5}>
          <RangeSlider
            max={50}
            min={0}
            name="distance"
            handleChangeCommitted={(newValue) =>
              setFilter({ ...filter, distance: newValue as number[] })
            }
            units="miles"
            defaultValue={filter?.distance}
          />
        </Box>
      </Stack>
    </>
  );
};
