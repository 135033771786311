import { Assistant } from "./Assistant";
import { useAssistantModalState, useAssistant } from "@services/Assistant";
import Grid from "@common/layout/Grid";
import { Drawer, Fab } from "@mui/material";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useEffect } from "react";
import { useUser } from "@services/User";

const welcomeMessageContent =
  "Welcome! I'm an AI assistant here to find your ideal golf tee times. Share your preferences such as location, date range, 9 or 18-hole courses, and price range. You can also inform me about the number of players. With these details, I can tailor the search results to your exact needs. Let's start finding the perfect tee time for you!. \n\n Disclaimer: The tee times provided by this assistant are based on available data and may not be completely accurate. Please verify the tee times directly with the golf course.";

export function TeeTimeAssistantDrawer() {
  const { data: userResponse } = useUser();
  const user = userResponse?.data;

  const assistantProps = useAssistant({
    userInitials: user ? user.firstName[0] + user.lastName[0] : undefined,
    loadingText: "I'm thinking, one moment please...",
    welcomeMessageContent,
    inputPlaceholderText:
      "Help me find teetimes near Minneapolis for 2 players this Thursday",
  });

  const maxWidth = Math.max(600, window.innerWidth * 0.2);

  const [assistantModalState, setAssistantModalState] =
    useAssistantModalState();

  useEffect(() => {
    if (assistantModalState.isOpen) {
      assistantProps.scrollToBottom();
    }
  }, [assistantModalState.isOpen]);

  return (
    <>
      <Drawer
        anchor="left"
        open={assistantModalState.isOpen}
        onClose={() => setAssistantModalState({ isOpen: false })}
        ModalProps={{ keepMounted: false }}
      >
        <Grid container maxWidth={maxWidth} minWidth={400} flex={1}>
          <Assistant {...assistantProps} />
        </Grid>
      </Drawer>
      <Fab
        color="primary"
        size="large"
        sx={{ position: "absolute", bottom: 25, left: 25 }}
        onClick={() => setAssistantModalState({ isOpen: true })}
        title="AI Assistant"
      >
        <AssistantIcon />
      </Fab>
    </>
  );
}
