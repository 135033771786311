import { IconButton, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { TextFieldWithController } from "@common/input/TextField";
import Grid from "components/common/layout/Grid";
import { MessageContainer } from "./MessageContainer";
import { useAssistant } from "@services/Assistant";
import Delete from "@mui/icons-material/Delete";

export function Assistant(props: ReturnType<typeof useAssistant>) {
  const {
    control,
    decoratedMessages,
    onSubmit,
    scrollViewRef,
    isLoading,
    userInitials,
    messageContainerHeight,
  } = props;
  const { palette } = useTheme();

  return (
    <Grid
      container
      direction="column"
      sx={{ backgroundColor: palette.grey.A100 }}
    >
      <Grid>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignContent="flex-end"
            justifySelf="flex-end"
            xs={12}
            p={1}
          >
            <Grid>
              <IconButton
                title="Delete the conversation"
                onClick={props.onClearThread}
                disabled={isLoading}
              >
                <Delete color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            sx={{
              overflow: "scroll",
              p: 4,
              height: messageContainerHeight,
            }}
            ref={scrollViewRef}
          >
            <MessageContainer
              messages={decoratedMessages}
              userInitials={userInitials}
            />
          </Grid>
          <Grid xs={12} container direction="column" flex={1}>
            <form onSubmit={onSubmit}>
              <Grid container direction="row" xs={12} p={2} spacing={2}>
                <Grid xs={11}>
                  <TextFieldWithController
                    control={control}
                    name={"message"}
                    fullWidth={true}
                    placeholder={props.inputPlaceholderText}
                  />
                </Grid>
                <Grid xs={1} justifyContent="center" alignSelf="center">
                  <IconButton
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    title="Submit"
                  >
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
