import { useParams as useParamsRR } from "react-router-dom";

type URLParameters = {
  location?: string;
  time?: string;
  date?: string;
  count?: string;
  courseId?: string;
  tabId?: string;
  secondaryId?: string;
};

const useParams = (): URLParameters => {
  const params = useParamsRR();

  const location = params?.location ? params.location : undefined;
  const time = params?.time ? params.time : undefined;
  const date = params?.date ? params.date : undefined;
  const count = params?.count ? params.count : undefined;
  const courseId = params?.courseId ? params.courseId : undefined;
  const tabId = params?.tabId ? params.tabId : undefined;
  const secondaryId = params?.secondaryId ? params.secondaryId : undefined;

  return {
    location,
    time,
    date,
    count,
    courseId,
    tabId,
    secondaryId,
  };
};

export default useParams;
