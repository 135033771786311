import { format, parseISO } from "date-fns";

import { Box, Typography, useTheme } from "@mui/material";
import { GolfIcon } from "@common";
import { Groups } from "@mui/icons-material";

type Props = {
  onClick?: () => void;
  playerMin: number;
  playerMax: number;
  date: string;
  holeCount: number;
  priceMin?: number | null;
  priceMax?: number | null;
};

export const MiniTeeTimeCard = ({
  onClick,
  playerMax,
  playerMin,
  date,
  holeCount,
  priceMin,
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "48%",
        marginBottom: theme.spacing(1.2),
        cursor: "pointer",
        padding: theme.spacing(1.2),
        border: "0.8px solid #E2E3E4",
        boxSizing: "border-box",
        borderRadius: theme.spacing(1),
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" color="textPrimary">
          {date && format(parseISO(date), "h:mm a")}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          {priceMin === 0 ? "See course page" : `$${priceMin}`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Groups
            sx={{
              color: "green.primary",
              marginRight: theme.spacing(0.8),
            }}
            fontSize="small"
          />
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              marginRight: theme.spacing(0.8),
            }}
            variant="body2"
          >
            {playerMin === playerMax ? playerMin : `${playerMin}-${playerMax}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <GolfIcon
            sx={{
              color: "green.primary",
              marginRight: theme.spacing(0.8),
            }}
            fontSize="small"
          />
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              marginRight: theme.spacing(0.8),
            }}
            variant="body2"
          >
            {holeCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
