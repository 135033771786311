import { IconButton, InputAdornment } from "@mui/material";
import {
  DatePickerProps,
  MobileDatePicker as DatePicker,
} from "@mui/x-date-pickers";
import { addDays, startOfToday } from "date-fns";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { CalendarToday } from "@mui/icons-material";

type DatePickerWithControllerProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  maxDate: Date;
} & Pick<DatePickerProps<Date>, "format" | "minDate" | "slotProps"> &
  Omit<DatePickerProps<Date>, "maxDate">;

export function DatePickerWithController<T extends FieldValues>({
  control,
  name,
  slotProps,
  minDate,
  maxDate,
}: DatePickerWithControllerProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          format="EEEE, LLL d"
          minDate={minDate ?? startOfToday()}
          maxDate={maxDate ?? addDays(startOfToday(), 3)}
          value={value}
          onChange={onChange}
          sx={{ svg: { fill: "#8CE21E" } }}
          slotProps={{
            ...slotProps,
            textField: {
              fullWidth: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton sx={{ marginLeft: "-12px" }}>
                      <CalendarToday sx={{ color: "green.primary" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      )}
    />
  );
}
