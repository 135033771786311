import { useNavigate as RrdNavigate } from "react-router-dom";
import {
  Filter,
  filterToQueryParam,
  useFilterQueryParams,
} from "@services/Search";

export const useNavigate = RrdNavigate;

export function useAppNavigate() {
  const navigate = useNavigate();
  const filterQueryParams = useFilterQueryParams();

  return {
    toHome() {
      navigate(`/${filterQueryParams}`);
    },
    toLogin() {
      navigate("/login");
    },
    toBasicSuccess() {
      navigate("/success?sub=free");
    },
    toCoursePage(id: string | number) {
      navigate(`/course/${id}${filterQueryParams}`);
    },
    toBookingPage({
      courseId,
      teetimeId,
    }: {
      courseId: string;
      teetimeId: string;
    }) {
      navigate(`/course/${courseId}/booking/${teetimeId}${filterQueryParams}`);
    },
    toCourseDetails(courseId: string) {
      navigate(`/course/${courseId}/details${filterQueryParams}`);
    },
    toCourseReviews(courseId: string) {
      navigate(`/course/${courseId}/reviews${filterQueryParams}`);
    },
    toResults(filter?: Filter) {
      const queryParams = filter
        ? filterToQueryParam(filter)
        : filterQueryParams;
      navigate(`/results${queryParams}`);
    },
    toChangePassword({ oobCode }: { oobCode: string }) {
      navigate(`/change-password?oobCode=${oobCode}`);
    },
  };
}
